import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  CheckCircle,
  Edit,
  Error,
  ExpandLess,
  ExpandMore,
  InsertChart,
} from '@mui/icons-material';
import {
  filter,
  find,
  forEach,
  get,
  includes,
  isArray,
  isEmpty,
  keys,
  map,
  reduce,
  some,
} from 'lodash';
import { useSearchParams } from 'react-router-dom';

import { enqueueSnackbar } from 'notistack';

import { containsOnly } from 'src/utils/misc';

import { alpha, Stack } from '@mui/system';

import {
  FloatingPanelContainer,
  FloatingPanelHeaderContainer,
  FloatingPanelHeaderLayoutContainer,
  FloatingPanelHeaderLayoutTextContainer,
  FloatingPanelHeaderLayoutTextIconsContainer,
} from 'src/components/shared/MultipleFilesUploadingPanel';
import { errorMessage } from 'src/utils/StaticErrorMessages';

import { poll } from 'src/components/ScenarioManager/EditAssumptionsDetail';

import useSetState from '../../../../../hooks/useSetState';
import {
  Model,
  ModelInstance,
  RowIdentifier,
  Scenario,
  Submission,
  SubmissionStatus,
  Timescale,
} from '../../../../../types/models';
import { useData } from '../../../../../hooks/useData';
import { parseId } from '../../../../../utils/parsing';
import { useProfile } from '../../../../../hooks/useProfile';
import * as API from '../../../../../services/API';

import { ISelectTimescaleValueProps } from '../../../../Visualization/TimescaleValuePickers/SelectTimescaleValue';

import { PortfolioDataSourceStatusEnum } from '../../../types';

import FullscreenProgress from '../../../../shared/FullScreenProgress';

import { IPortfolioReportStateData } from '../../../index';

import EditChartPanel, { WaterfallColumns } from './EditChartPanel';
import GenerateChartPanel from './GenerateChartPanel';

interface WaterfallState {
  modelId: Model['id'] | undefined;
  modelInstanceId: ModelInstance['id'] | undefined;
  fromScenarioId: Scenario['id'] | undefined;
  toScenarioId: Scenario['id'] | undefined;
  outputId: RowIdentifier['id'] | undefined;
  timescale: Timescale | undefined;
  chartType: string | undefined;
  isPanelCollapsed: boolean;
  isEditChartPanelOpen: boolean;
  isGenerateChartPanelOpen: boolean;
  waterFallData: any[];
  chartLoading: boolean;
  blobURL: string | undefined;
  formatKey: number | null;
  timeScaleValue: ISelectTimescaleValueProps['value'] | string[] | null;
  columns: WaterfallColumns[];
  dimensionInstances: RowIdentifier[];
  groupedSubmissions: Record<
    Submission['id'],
    Submission & { GroupName: string }
  >;
  displaySubmissionsRefreshPanel: boolean;
  isSubmissionsRefreshPanelExpanded: boolean;
  isPublishChangesDialogLoading: boolean;
  ForecastDataRefreshID: number | undefined;
  isGenerateChartApiCalled: boolean;
}

interface WaterfallProps {
  selectedReport?: IPortfolioReportStateData;
  isThereAnUnsavedComponent: (bool: boolean) => void;
  refresh: () => void;
  navigateToReportsHomePage: () => void;
}

const Waterfall: FunctionComponent<WaterfallProps> = ({
  selectedReport,
  isThereAnUnsavedComponent,
  refresh,
  navigateToReportsHomePage,
}) => {
  const [state, setState] = useSetState<WaterfallState>({
    chartLoading: false,
    blobURL: undefined,
    isPanelCollapsed: false,
    isEditChartPanelOpen: true,
    isGenerateChartPanelOpen: false,
    modelId: undefined,
    modelInstanceId: undefined,
    fromScenarioId: undefined,
    toScenarioId: undefined,
    outputId: undefined,
    timescale: undefined,
    chartType: undefined,
    waterFallData: [],
    formatKey: null,
    timeScaleValue: null,
    columns: [],
    dimensionInstances: [],
    groupedSubmissions: {},
    displaySubmissionsRefreshPanel: false,
    isSubmissionsRefreshPanelExpanded: true,
    isPublishChangesDialogLoading: false,
    ForecastDataRefreshID: undefined,
    isGenerateChartApiCalled: false,
  });

  const profile = useProfile();

  const [searchParams] = useSearchParams();

  const clientId =
    profile && profile.User.ClientID !== null
      ? profile.User.ClientID
      : parseId(searchParams.get('client'));
  
  useEffect(() => {
    setState({ groupedSubmissions: {} });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (
        containsOnly(
          [SubmissionStatus.Finished],
          map(state.groupedSubmissions, 'Status')
        )
      ) {
        const chartData = await API.load(
          `/chart_refresh/${state.ForecastDataRefreshID}`
        );
        setState({
          waterFallData: chartData?.data,
          blobURL: chartData?.ExcelBlobURL,
          isEditChartPanelOpen: false,
          isGenerateChartPanelOpen: true,
          chartLoading: false,
          displaySubmissionsRefreshPanel: false,
          isPublishChangesDialogLoading: false,
        });
      } else if (
        some(
          map(state.groupedSubmissions, 'Status'),
          (i) => i === SubmissionStatus.Failed
        )
      ) {
        setState({
          isEditChartPanelOpen: false,
          isGenerateChartPanelOpen: false,
          chartLoading: false,
          isPublishChangesDialogLoading: true,
        });
      }
    };
    fetchData();
  }, [state.groupedSubmissions]);

  useEffect(() => {
    const runReport = searchParams.get('runReport');
    (async () => {
      if (
        selectedReport &&
        selectedReport.ModelInstanceID &&
        runReport === 'true' &&
        !state.isGenerateChartApiCalled
      ) {
        setState({ isGenerateChartApiCalled: true });
        const rowIdentifiers = await API.load(
          `/instances/${selectedReport.ModelInstanceID}/row_identifiers`
        );
        setState(
          {
            isGenerateChartPanelOpen: false,
            isEditChartPanelOpen: false,
            chartLoading: true,
          },
          () => {
            const chartType = selectedReport.Metadata.WaterfallChart.DateId_End
              ? 'cumulative'
              : 'pointintime';
            generateChart(
              selectedReport.Metadata.WaterfallChart.OutputName,
              selectedReport.Metadata.WaterfallChart.Timescale,
              selectedReport.Metadata.WaterfallChart.ScenarioId_A,
              selectedReport.Metadata.WaterfallChart.ScenarioId_B,
              chartType,
              chartType === 'pointintime'
                ? selectedReport.Metadata.WaterfallChart.DateId_Start
                : [
                    selectedReport.Metadata.WaterfallChart.DateId_Start,
                    selectedReport.Metadata.WaterfallChart.DateId_End,
                  ],
              map(selectedReport.Metadata.WaterfallColumns, (i, idx) => {
                return {
                  id: idx + 1,
                  name: i.Name,
                  inputs: filter(
                    filter(rowIdentifiers, { Type: 'Input' }),
                    (f) => i.InputNames.includes(f.Name)
                  ),
                };
              }) as any[],
              filter(
                filter(rowIdentifiers, { Type: 'DimensionInstance' }),
                (f) =>
                  selectedReport?.Metadata.WaterfallChart.RowIdentifierIDs.includes(
                    f.id
                  )
              ),
              rowIdentifiers
            );
          }
        );
      }
    })();
  }, [searchParams, selectedReport]);

  useEffect(() => {
    (async () => {
      if (selectedReport?.ModelInstanceID) {
        const rowIdentifiers = await API.load(
          `/instances/${selectedReport?.ModelInstanceID}/row_identifiers`
        );
        setState({
          modelId: selectedReport?.ModelID,
          modelInstanceId: selectedReport?.ModelInstanceID,
          ...(selectedReport?.Metadata && {
            outputId: find(filter(rowIdentifiers, { Type: 'Output' }), {
              Name: selectedReport?.Metadata.WaterfallChart.OutputName,
            })?.id,
            fromScenarioId:
              selectedReport?.Metadata.WaterfallChart.ScenarioId_A,
            toScenarioId: selectedReport?.Metadata.WaterfallChart.ScenarioId_B,
            chartType: selectedReport?.Metadata.WaterfallChart.DateId_End
              ? 'cumulative'
              : 'pointintime',
            timescale: selectedReport?.Metadata.WaterfallChart.Timescale,
            timeScaleValue:
              selectedReport?.Metadata.WaterfallChart.DateId_End === null
                ? selectedReport?.Metadata.WaterfallChart.DateId_Start
                : [
                    selectedReport?.Metadata.WaterfallChart.DateId_Start,
                    selectedReport?.Metadata.WaterfallChart.DateId_End,
                  ],
            columns: map(
              selectedReport?.Metadata.WaterfallColumns,
              (i, idx) => {
                return {
                  id: idx + 1,
                  name: i.Name,
                  inputs: filter(
                    filter(rowIdentifiers, { Type: 'Input' }),
                    (f) => i.InputNames.includes(f.Name)
                  ),
                };
              }
            ),
            dimensionInstances: filter(
              filter(rowIdentifiers, { Type: 'DimensionInstance' }),
              (f) =>
                selectedReport?.Metadata.WaterfallChart.RowIdentifierIDs.includes(
                  f.id
                )
            ),
            formatKey: (
              find(rowIdentifiers, {
                id: find(filter(rowIdentifiers, { Type: 'Output' }), {
                  Name: selectedReport?.Metadata.WaterfallChart.OutputName,
                })?.id,
              }) as RowIdentifier
            ).FormatKey,
          }),
        });
      }
    })();
  }, [selectedReport]);

  const { data } = useData<{
    rowIdentifiers?: RowIdentifier[];
    model?: Model;
    modelInstance?: ModelInstance;
    scenarios?: Scenario[];
  }>(
    () => ({
      rowIdentifiers:
        state.modelInstanceId !== undefined
          ? `/instances/${state.modelInstanceId}/row_identifiers`
          : undefined,
      model:
        clientId !== undefined && state.modelId !== undefined
          ? `/clients/${clientId}/models/${state.modelId}`
          : undefined,
      modelInstance:
        clientId !== undefined && state.modelId !== undefined
          ? `/instances/${state.modelInstanceId}`
          : undefined,
      scenarios:
        state.modelInstanceId !== undefined
          ? `/instances/${state.modelInstanceId}/scenarios_load/finished`
          : undefined,
    }),
    [state.modelInstanceId, state.modelId]
  );

  const outputs = useMemo(
    () => filter(data.rowIdentifiers, { Type: 'Output' }),
    [state.modelInstanceId, data]
  );

  const handleCloseEditChartPanel = useCallback(() => {
    setState({
      isEditChartPanelOpen: false,
      isGenerateChartPanelOpen: true,
    });
  }, [state.isEditChartPanelOpen, state.isGenerateChartPanelOpen]);

  const generateChart = async (
    outputName: RowIdentifier['Name'],
    timescale: Timescale,
    fromScenarioId: Scenario['id'],
    toScenarioId: Scenario['id'],
    chartType: string,
    timeScaleValue: any,
    columns: WaterfallColumns[],
    dimensionInstances: RowIdentifier[],
    rowIdentifiers: RowIdentifier[]
  ) => {
    if (
      outputName &&
      fromScenarioId &&
      toScenarioId &&
      timeScaleValue &&
      !isEmpty(columns)
    ) {
      setState({
        groupedSubmissions: {},
        chartLoading: true,
        isEditChartPanelOpen: false,
        isGenerateChartPanelOpen: true,
        isPublishChangesDialogLoading: true,
      });
      try {
        const result: {
          ForecastDataRefresh: number;
          ForecastDataRefreshID: number;
          RowIdentifier: number;
          RowIdentifierID: number;
          // Status: SubmissionStatus;
          Status_Message: string | null;
          created_at: string;
          id: number;
        }[] = await API.create(`/chart_refresh/waterfall`, {
          WaterfallChart: {
            Timescale: timescale,
            OutputName: outputName,
            RowIdentifierIDs: dimensionInstances.map(
              (dimensionInstance) => dimensionInstance.id
            ),
            DateId_Start:
              chartType === 'pointintime' ? timeScaleValue : timeScaleValue[0],
            DateId_End: chartType === 'pointintime' ? null : timeScaleValue[1],
            ScenarioId_A: fromScenarioId,
            ScenarioId_B: toScenarioId,
          },
          WaterfallColumns: map(columns, (column, idx) => {
            return {
              Order: idx + 1,
              Name: column.name,
              InputNames: map(column.inputs, 'Name'),
            };
          }),
        });

        if (isArray(result)) {
          setState({
            groupedSubmissions: reduce(
              result,
              (acc: WaterfallState['groupedSubmissions'], c) => {
                const submission = find(state.groupedSubmissions, {
                  id: c.id,
                }) as Submission;
                acc[c.id] = {
                  ...submission,
                  ...c,
                  GroupName:
                    find(rowIdentifiers, { id: c.RowIdentifierID })?.Name || '',
                  Status_Message: c.Status_Message ?? undefined,
                };
                return acc;
              },
              {}
            ),
            displaySubmissionsRefreshPanel: true,
            isPublishChangesDialogLoading: true,
            ForecastDataRefreshID: result[0]?.ForecastDataRefreshID,
          });

          const submissionIds = map(result, 'id');
          forEach(submissionIds, async (id) => {
            let fetchSubmission = () =>
              API.load(`/calculation_invocations/${id}`);
            let validate = (result: Submission) =>
              includes(
                [SubmissionStatus.Started, SubmissionStatus.Queued],
                result.Status
              );
            let response = await poll(fetchSubmission, validate, 2500);
            if (response.Status !== PortfolioDataSourceStatusEnum.FINISHED) {
              enqueueSnackbar(response.Status_Message, {
                variant: 'error',
              });
            }
            setState((prevState) => ({
              groupedSubmissions: {
                ...prevState.groupedSubmissions,
                [id]: {
                  ...response,
                  GroupName: find(rowIdentifiers, {
                    id: response.RowIdentifierID,
                  })?.Name,
                },
              },
            }));
          });
        } else {
          enqueueSnackbar('Please select all required fields', {
            variant: 'error',
          });
        }
      } catch (error: any) {
        enqueueSnackbar(error.body.message, { variant: 'error' });
        setState({
          isEditChartPanelOpen: true,
          isGenerateChartPanelOpen: false,
          chartLoading: false,
          isPublishChangesDialogLoading: false,
          displaySubmissionsRefreshPanel: false
        });
        return;
      }
    }
  };

  const handleSaveEditChartPanel = async (chartData: {
    modelId?: Model['id'];
    modelInstanceId?: ModelInstance['id'];
    fromScenarioId: Scenario['id'];
    toScenarioId: Scenario['id'];
    outputId: RowIdentifier['id'];
    timescale: Timescale;
    chartType: string;
    timeScaleValue: ISelectTimescaleValueProps['value'] | string[] | null;
    columns: WaterfallColumns[];
    dimensionInstances: RowIdentifier[];
  }) => {
    const rowIdentifiers = await API.load(
      `/instances/${chartData.modelInstanceId}/row_identifiers`
    );
    setState({
      modelId: chartData.modelId,
      modelInstanceId: chartData.modelInstanceId,
      fromScenarioId: chartData.fromScenarioId,
      toScenarioId: chartData.toScenarioId,
      outputId: chartData.outputId,
      timescale: chartData.timescale,
      chartType: chartData.chartType,
      timeScaleValue: chartData.timeScaleValue,
      columns: chartData.columns,
      dimensionInstances: chartData.dimensionInstances,
      formatKey: (
        find(rowIdentifiers, { id: chartData.outputId }) as RowIdentifier
      ).FormatKey,
    });
    generateChart(
      find(rowIdentifiers, { id: chartData.outputId })?.Name || '',
      chartData.timescale,
      chartData.fromScenarioId,
      chartData.toScenarioId,
      chartData.chartType,
      chartData.timeScaleValue,
      chartData.columns,
      chartData.dimensionInstances,
      rowIdentifiers
    );
  };

  const dimensions = useMemo(
    () => filter(data.rowIdentifiers, { Type: 'Dimension' }),
    [data]
  );

  const selectedDimensionInstances = useMemo(
    () =>
      reduce(
        state.dimensionInstances,
        (a: Record<string, RowIdentifier[]>, c, idx, arr) => {
          const findDimensionByDimNumber = find(dimensions, {
            DimNumber: c.DimNumber,
          }) as RowIdentifier;
          a[findDimensionByDimNumber?.Name] = filter(arr, {
            DimNumber: get(findDimensionByDimNumber, 'DimNumber'),
          });
          return a;
        },
        {}
      ),
    [state.dimensionInstances, dimensions]
  );

  return (
    <Box>
      <Grid container sx={{ my: 2, width: '100%', height: '100%' }} spacing={0}>
        <Grid
          xs={state.isPanelCollapsed ? 0.75 : 2.5}
          sx={{
            transition: (theme) =>
              theme.transitions.create('all', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
          }}
        >
          <Card
            sx={{
              height: '100%',
              mr: 2,
            }}
          >
            <CardContent
              sx={{
                background: 'white',
                '& hr': {
                  my: 1,
                },
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                {!state.isPanelCollapsed && (
                  <Grid sm={6}>
                    <Typography variant="h6">Chart Data</Typography>
                  </Grid>
                )}
                {state.isGenerateChartPanelOpen && (
                  <Grid sm={6} container justifyContent={'flex-end'}>
                    <Tooltip title="Edit Chart" arrow>
                      <IconButton
                        onClick={() =>
                          setState({
                            isEditChartPanelOpen: true,
                            isGenerateChartPanelOpen: false,
                          })
                        }
                      >
                        <Edit fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
              <Divider />
              {state.isPanelCollapsed ? (
                <Grid
                  container
                  direction="column"
                  sx={{
                    display: 'block',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  spacing={1}
                >
                  <Grid xs={12} sx={{ textAlign: 'center', mt: 1 }}>
                    {!state.isEditChartPanelOpen && (
                      <>
                        {state.isPanelCollapsed && (
                          <Tooltip title="Edit Chart" arrow>
                            <IconButton
                              size="small"
                              onClick={() =>
                                setState({
                                  isEditChartPanelOpen: true,
                                  isGenerateChartPanelOpen: false,
                                })
                              }
                            >
                              <Edit fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </>
                    )}
                  </Grid>
                  <Grid xs={12} sx={{ textAlign: 'center', mt: 1 }}>
                    {state.isPanelCollapsed && (
                      <Tooltip title="Format Chart" arrow>
                        <IconButton
                          size="small"
                          onClick={() =>
                            setState({
                              isEditChartPanelOpen: false,
                              isGenerateChartPanelOpen: false,
                            })
                          }
                        >
                          <InsertChart fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              ) : (
                <>
                  <Grid container sx={{ mt: 2 }}>
                    <Grid xs={12}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        Model
                      </Typography>
                    </Grid>
                    <Grid xs={12}>
                      {state.modelId ? data?.model?.Name : ''}
                    </Grid>
                  </Grid>
                  <Grid container sx={{ mt: 2 }}>
                    <Grid xs={12}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        Model Instance
                      </Typography>
                    </Grid>
                    <Grid xs={12}>
                      <Typography sx={{ wordBreak: 'break-all' }}>
                        {state.modelInstanceId ? data?.modelInstance?.Name : ''}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ mt: 2 }}>
                    <Grid xs={12}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        Scenarios
                      </Typography>
                    </Grid>
                    <Grid xs={12}>
                      {state.fromScenarioId
                        ? get(
                            find(
                              data.scenarios,
                              (i) => i.id === state.fromScenarioId
                            ) as Scenario,
                            'Name',
                            ''
                          )
                        : ''}{' '}
                      -{' '}
                      {state.toScenarioId
                        ? get(
                            find(
                              data.scenarios,
                              (i) => i.id === state.toScenarioId
                            ) as Scenario,
                            'Name',
                            ''
                          )
                        : ''}
                    </Grid>
                  </Grid>
                </>
              )}
              <Grid container sx={{ mt: 2 }}>
                <Grid xs={12}>
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    Output
                  </Typography>
                </Grid>
                <Grid xs={12}>
                  {state.outputId
                    ? find(outputs, {
                        id: state.outputId,
                      })?.Name
                    : ''}
                </Grid>
              </Grid>
              <Grid container sx={{ mt: 2 }}>
                <Grid xs={12}>
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    Timescale
                  </Typography>
                </Grid>
                <Grid xs={12}>{state.timescale}</Grid>
              </Grid>
              <Grid container sx={{ mt: 2 }}>
                <Grid xs={12}>
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    Segments
                  </Typography>
                </Grid>
                <Grid xs={12}>
                  {map(keys(selectedDimensionInstances), (dimension) => {
                    return (
                      <Box key={dimension}>
                        <Typography
                          sx={{
                            color: (t) => t.palette.primary.main,
                            fontWeight: 'bold',
                          }}
                        >
                          {dimension}
                        </Typography>
                        {map(selectedDimensionInstances[dimension], (d) => (
                          <Box key={d.id}>{d.Name}</Box>
                        ))}
                      </Box>
                    );
                  })}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          xs={state.isPanelCollapsed ? 11.25 : 9.5}
          sx={{
            transition: (theme) =>
              theme.transitions.create('all', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
          }}
        >
          <Card sx={{ background: 'white', height: '100%' }}>
            <CardContent
              sx={{
                paddingBottom: 2,
                marginBottom: 2,
                '&:last-child': {
                  padding: 0,
                },
              }}
            >
              {state.chartLoading && <FullscreenProgress />}

              {state.isEditChartPanelOpen && !state.chartLoading && (
                <EditChartPanel
                  open={state.isEditChartPanelOpen}
                  handleCloseEditChartPanel={handleCloseEditChartPanel}
                  handleSaveEditChartPanel={handleSaveEditChartPanel}
                  selectedModel={state.modelId}
                  selectedModelInstance={state.modelInstanceId}
                  selectedFromScenario={state.fromScenarioId}
                  selectedToScenario={state.toScenarioId}
                  selectedOutput={state.outputId}
                  selectedTimescale={state.timescale}
                  selectedChartType={state.chartType}
                  selectedReport={selectedReport}
                  selectedTimeScaleValue={state.timeScaleValue}
                  selectedColumns={state.columns}
                  selectedDimensionInstances={state.dimensionInstances}
                  isThereAnUnsavedComponent={isThereAnUnsavedComponent}
                  refresh={refresh}
                />
              )}
              {state.isGenerateChartPanelOpen && !state.chartLoading && (
                <GenerateChartPanel
                  open={state.isGenerateChartPanelOpen}
                  data={state.waterFallData}
                  blobURL={state.blobURL}
                  formatKey={state.formatKey}
                  outputName={
                    find(data.rowIdentifiers, { id: state.outputId })?.Name ||
                    ''
                  }
                />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Backdrop
        sx={{
          color: 'white',
          zIndex: (theme) => theme.zIndex.drawer + 3,
          backgroundColor: alpha('#596B7A', 0.7),
        }}
        open={state.isPublishChangesDialogLoading}
      >
        <Stack direction="column" spacing={2} alignItems="center">
          <>
            {!isEmpty(map(state.groupedSubmissions, 'Status')) ? (
              <>
                {some(
                  map(state.groupedSubmissions, 'Status'),
                  (i) =>
                    i === SubmissionStatus.Started ||
                    i === SubmissionStatus.Queued
                ) && (
                  <Typography variant="h6">
                    Chart generation is in progress
                  </Typography>
                )}
                {containsOnly(
                  [SubmissionStatus.Finished],
                  map(state.groupedSubmissions, 'Status')
                ) && (
                  <Typography variant="h6">
                    Chart generation successfully completed
                  </Typography>
                )}
              </>
            ) : (
              <Typography variant="h6">
                Chart generation is in progress
              </Typography>
            )}
          </>
          {(() => {
            if (
              containsOnly(
                [SubmissionStatus.Finished],
                map(state.groupedSubmissions, 'Status')
              )
            ) {
              return <Typography>Refreshing...</Typography>;
            } else if (
              some(
                map(state.groupedSubmissions, 'Status'),
                (i) => i === SubmissionStatus.Failed
              )
            ) {
              return (
                <>
                  <Typography variant="h6">Chart Generation Failed</Typography>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setState({
                        isPublishChangesDialogLoading: false,
                        displaySubmissionsRefreshPanel: false,
                      });
                      navigateToReportsHomePage();
                    }}
                  >
                    Close
                  </Button>
                </>
              );
            } else if (
              some(
                map(state.groupedSubmissions, 'Status'),
                (i) => i === SubmissionStatus.NoDataReturned
              )
            ) {
              enqueueSnackbar(
                find(state.groupedSubmissions, {
                  Status: SubmissionStatus.NoDataReturned,
                })?.Status_Message,
                { variant: 'warning' }
              );
              return (
                <Button
                  variant="contained"
                  onClick={() => {
                    setState({
                      isPublishChangesDialogLoading: false,
                      displaySubmissionsRefreshPanel: false,
                    });
                    navigateToReportsHomePage();
                  }}
                >
                  Close
                </Button>
              );
            }
          })()}
          {state.displaySubmissionsRefreshPanel &&
            !isEmpty(state.groupedSubmissions) && (
              <FloatingPanelContainer>
                <FloatingPanelHeaderContainer>
                  <FloatingPanelHeaderLayoutContainer>
                    <FloatingPanelHeaderLayoutTextContainer>
                      <Typography sx={{ lineHeight: 'inherit' }}>
                        Chart Generation
                      </Typography>
                    </FloatingPanelHeaderLayoutTextContainer>
                    <Box style={{ flex: '0 0 auto' }}>
                      <FloatingPanelHeaderLayoutTextIconsContainer>
                        <IconButton
                          sx={{
                            height: '40px',
                            width: '40px',
                            margin: '0 4px',
                          }}
                          onClick={() =>
                            setState({
                              isSubmissionsRefreshPanelExpanded:
                                !state.isSubmissionsRefreshPanelExpanded,
                            })
                          }
                        >
                          {state.isSubmissionsRefreshPanelExpanded ? (
                            <ExpandMore sx={{ color: 'white' }} />
                          ) : (
                            <ExpandLess sx={{ color: 'white' }} />
                          )}
                        </IconButton>
                      </FloatingPanelHeaderLayoutTextIconsContainer>
                    </Box>
                  </FloatingPanelHeaderLayoutContainer>
                </FloatingPanelHeaderContainer>
                <Box>
                  <Collapse in={state.isSubmissionsRefreshPanelExpanded}>
                    <Box
                      sx={{
                        width: '100%',
                        bgcolor: 'background.paper',
                        border: `1px solid #323232`,
                      }}
                    >
                      <List
                        sx={{
                          width: '498px',
                          maxHeight: '300px',
                          overflow: 'auto',
                          overflowX: 'hidden',
                        }}
                      >
                        {map(state.groupedSubmissions, (i) => {
                          return (
                            <Fragment key={i.id}>
                              <ListItem
                                secondaryAction={
                                  <>
                                    {(() => {
                                      switch (i.Status) {
                                        case SubmissionStatus.Finished:
                                        case SubmissionStatus.NoDataReturned:
                                          return (
                                            <CheckCircle color="success" />
                                          );
                                        case SubmissionStatus.Failed:
                                          return (
                                            <Tooltip
                                              title={
                                                i.Status_Message &&
                                                i.Status_Message.includes(
                                                  'InputRowID'
                                                ) &&
                                                JSON.parse(i.Status_Message)
                                                  ?.length > 0
                                                  ? errorMessage.DATE_LIKE_INPUT_ERROR_MESSAGE
                                                  : i.Status_Message
                                              }
                                              arrow
                                            >
                                              <Error color="error" />
                                            </Tooltip>
                                          );
                                        case SubmissionStatus.Started:
                                          return <CircularProgress size={25} />;
                                        case SubmissionStatus.Queued:
                                          return <CircularProgress size={25} />;
                                      }
                                    })()}
                                  </>
                                }
                              >
                                <ListItemText>
                                  <Tooltip
                                    arrow
                                    title={i.GroupName ? i.GroupName : 'Status'}
                                  >
                                    <Typography sx={{ color: 'black' }} noWrap>
                                      {i.GroupName ? i.GroupName : 'Status'}
                                    </Typography>
                                  </Tooltip>
                                </ListItemText>
                              </ListItem>
                              <Divider />
                            </Fragment>
                          );
                        })}
                      </List>
                    </Box>
                  </Collapse>
                </Box>
              </FloatingPanelContainer>
            )}
        </Stack>
      </Backdrop>
    </Box>
  );
};

export default Waterfall;

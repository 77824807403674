import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import {
  capitalize,
  concat,
  filter,
  find,
  findIndex,
  forEach,
  get,
  has,
  head,
  includes,
  isEmpty,
  isUndefined,
  keys,
  last,
  lowerCase,
  map,
  omit,
  parseInt,
  reduce,
  reject,
  replace,
  slice,
  some,
  uniqBy,
  upperFirst,
} from 'lodash';
import { useSnackbar } from 'notistack';
import {
  Add,
  CheckCircle,
  Close,
  ContentCopy,
  Edit,
  Error,
  ExpandLess,
  ExpandMore,
  InsertChart,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@mui/icons-material';
import { useLocation, useSearchParams } from 'react-router-dom';

import { alpha } from '@mui/material/styles';

import { errorMessage } from 'src/utils/StaticErrorMessages';

import useSetState from '../../../../hooks/useSetState';
import {
  GeneratedFile,
  Model,
  ModelInstance,
  RowIdentifier,
  Scenario,
  SubmissionStatus,
  Timescale,
} from '../../../../types/models';

import * as API from '../../../../services/API';
import { getDataAtIntervals } from '../../../../utils/getDataAtIntervals';

import { useData } from '../../../../hooks/useData';

import {
  filterObject,
  IBooleanType,
  IChartTab,
  INumberType,
  IStringType,
  portfolioNumScenarios,
} from '../../../Visualization/visualizationConstants';

import FullscreenProgress from '../../../shared/FullScreenProgress';

import {
  formatParameters,
  IFormatOutputParameters,
} from '../../../Visualization/formatParameters';

import DraggableTabs from '../../../shared/DraggableTabs';

import {
  IVisualizationContext,
  VisualizationContext,
} from '../../../Visualization/VisualizationContext';

import RemoveChartDialog from '../../../Visualization/RemoveChartDialog';

import { useProfile } from '../../../../hooks/useProfile';

import { parseId } from '../../../../utils/parsing';

import {
  EditAssumptionsContext,
  EditAssumptionsDetailContext,
} from '../EditAssumptionsDetailContext';

import { WEB_ASSUMPTIONS_SANDBOX_PREVIEW } from '../../../../url';

import useDynamicCallback from '../../../../hooks/useDynamicCallback';

import { ISandboxRefresh } from '../types';

import { containsOnly } from '../../../../utils/misc';

import {
  FloatingPanelContainer,
  FloatingPanelHeaderContainer,
  FloatingPanelHeaderLayoutContainer,
  FloatingPanelHeaderLayoutTextContainer,
  FloatingPanelHeaderLayoutTextIconsContainer,
} from '../../../shared/MultipleFilesUploadingPanel';

import EditChartTitleDialog from '../../../Visualization/EditChartTitleDialog';

import EditChartPanel from './EditChartPanel';
import GenerateChartPanel, {
  chartDataTypes,
  chartTypes,
} from './GenerateChartPanel';
import FormatChartPanel, { dateFormats } from './FormatChartPanel';

export interface IChartDataSeries {
  scenario: string;
  segment: string;
  name: string;
  values: number[];
  id: number;
  output?: string;
  visible: boolean;
}

export interface IChartDataDate {
  DateId: number;
  Date: string;
}

export interface IChartData {
  PreviewData: IChartDataSeries[];
  PublishedData: IChartDataSeries[];
  Dates: IChartDataDate[];
}

interface IOutputsPanelProps {
  inContainer?: boolean;
  showScenarios?: boolean;
  setInvalidInputs?: (invalidInputs: any) => void;
}

export interface IOutputsPanelState {
  isEditChartPanelOpen: IBooleanType;
  isGenerateChartPanelOpen: IBooleanType;
  isFormatChartPanelOpen: IBooleanType;
  selectedChartTab: string;
  chartTabs: IChartTab[];
  modelId: Record<string, Model['id'] | undefined>;
  modelInstanceId: Record<string, ModelInstance['id'] | undefined>;
  outputId: Record<string, RowIdentifier['id'] | undefined>;
  timescale: Record<string, Timescale | undefined>;
  dimensionInstances: Record<string, RowIdentifier[]>;
  scenarios: Record<string, Scenario['id'][]>;
  refreshChart: IBooleanType;
  showEditChartTitleModal: IBooleanType;
  chartLoading: IBooleanType;
  aggregateFlag: IBooleanType;
  chartYAxisTitle: IStringType;
  chartData: Record<string, IChartData>;
  refreshedChartData: Record<string, IChartData>;
  chartFormatKey: Record<string, number | undefined>;
  chartTitle: IStringType;
  chartDateType: IStringType;
  selectedMagnitudeTruncationValue: INumberType;
  selectedDecimalPlacesValue: INumberType;
  selectedPrefixValue: IStringType;
  selectedSuffixValue: IStringType;
  selectedChartRange: Record<string, [string, string] | null>;
  selectedChartRangeIndex: Record<string, [number, number] | undefined>;
  showRemoveChartModal: string;
  selectedChartType: Record<string, string>;
  selectedChartDataType: Record<string, string>;
  isPanelCollapsed: IBooleanType;
  isSandboxChangesDialogLoading: boolean;
  groupedSandboxRefreshes: (ISandboxRefresh & { GroupName: string })[];
  displaySandboxRefreshPanel: boolean;
  isSandboxRefreshPanelExpanded: boolean;
}

const OutputsPanel: FunctionComponent<IOutputsPanelProps> = ({
  inContainer,
  showScenarios,
  setInvalidInputs,
}) => {
  const visualizationContext = useContext<IVisualizationContext | null>(
    VisualizationContext
  );
  const editAssumptionsContext = useContext<EditAssumptionsContext | null>(
    EditAssumptionsDetailContext
  );

  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const profile = useProfile();

  const clientId =
    profile && profile.User.ClientID !== null
      ? profile.User.ClientID
      : parseId(searchParams.get('client'));
  const modelInstanceId = Number(searchParams.get('instance'));

  const chartState = useMemo(
    () => ({
      selectedChartTab: 'chart1',
      chartTabs: [{ eventKey: 'chart1', title: 'Chart 1' }],
      modelId: {
        chart1: !showScenarios ? Number(searchParams.get('model')) : undefined,
      },
      modelInstanceId: {
        chart1: !showScenarios
          ? Number(searchParams.get('instance'))
          : undefined,
      },
      outputId: { chart1: undefined },
      timescale: { chart1: undefined },
      dimensionInstances: { chart1: [] },
      scenarios: {
        chart1: !showScenarios ? [Number(searchParams.get('scenario'))] : [],
      },
      refreshChart: { chart1: false },
      showEditChartTitleModal: { chart1: false },
      showRemoveChartModal: '',
      chartLoading: { chart1: false },
      aggregateFlag: { chart1: true },
      chartYAxisTitle: { chart1: '' },
      chartTitle: { chart1: '' },
      chartDateType: { chart1: '' },
      chartData: { chart1: { PreviewData: [], PublishedData: [], Dates: [] } },
      refreshedChartData: {
        chart1: { PreviewData: [], PublishedData: [], Dates: [] },
      },
      chartFormatKey: { chart1: undefined },
      selectedMagnitudeTruncationValue: { chart1: 0 },
      selectedDecimalPlacesValue: { chart1: 0 },
      selectedPrefixValue: { chart1: '' },
      selectedSuffixValue: { chart1: '' },
      selectedChartRange: { chart1: null },
      selectedChartRangeIndex: { chart1: undefined },
      selectedChartType: { chart1: chartTypes[0].id },
      selectedChartDataType: { chart1: chartDataTypes[0].id },
      isEditChartPanelOpen: { chart1: true },
      isGenerateChartPanelOpen: { chart1: false },
      isFormatChartPanelOpen: { chart1: false },
      isPanelCollapsed: { chart1: false },
      groupedSandboxRefreshes: [],
      isSandboxChangesDialogLoading: false,
      isSandboxRefreshPanelExpanded: true,
      displaySandboxRefreshPanel: false,
    }),
    [showScenarios, searchParams]
  );

  const [state, setState] = useSetState<IOutputsPanelState>(chartState);

  useEffect(() => {
    if (
      editAssumptionsContext &&
      !isUndefined(editAssumptionsContext!.outputsChartData) &&
      !isEmpty(editAssumptionsContext!.outputsChartData)
    ) {
      setState(editAssumptionsContext!.outputsChartData);
    }
  }, [editAssumptionsContext]);

  useEffect(() => {
    if (showScenarios) {
      if (
        visualizationContext &&
        visualizationContext.childrenData &&
        visualizationContext.childrenData.customChart
      ) {
        setState(visualizationContext.childrenData.customChart);
      }
    }
  }, [showScenarios]);

  useEffect(() => {
    if (showScenarios) {
      if (
        visualizationContext &&
        has(visualizationContext, 'handleChildrenChange')
      ) {
        visualizationContext!.handleChildrenChange('customChart', state);
      }
    }
  }, [state, showScenarios]);

  const { data } = useData<{
    rowIdentifiers?: RowIdentifier[];
    model?: Model;
    modelInstance?: ModelInstance;
    scenarios?: Scenario[];
  }>(
    () => ({
      rowIdentifiers:
        state.modelInstanceId[state.selectedChartTab] !== undefined
          ? `/instances/${
              state.modelInstanceId[state.selectedChartTab]
            }/row_identifiers`
          : undefined,
      model:
        clientId !== undefined &&
        state.modelId[state.selectedChartTab] !== undefined
          ? `/clients/${clientId}/models/${
              state.modelId[state.selectedChartTab]
            }`
          : undefined,
      modelInstance:
        clientId !== undefined &&
        state.modelId[state.selectedChartTab] !== undefined
          ? `/instances/${state.modelInstanceId[state.selectedChartTab]}`
          : undefined,
      scenarios:
        state.modelInstanceId[state.selectedChartTab] !== undefined
          ? `/instances/${
              state.modelInstanceId[state.selectedChartTab]
            }/scenarios_load/finished`
          : undefined,
    }),
    [
      state.modelInstanceId[state.selectedChartTab],
      state.modelId[state.selectedChartTab],
    ]
  );

  useEffect(() => {
    (async () => {
      if (
        containsOnly(
          [SubmissionStatus.Finished],
          map(state.groupedSandboxRefreshes, 'Status')
        )
      ) {
        const calculationsData = state.groupedSandboxRefreshes;
        const getUniqueCalculationData = uniqBy(
          calculationsData,
          'ForecastDataRefreshID'
        );

        try {
          const chartJsonData = await getDataAtIntervals(
            `/chart_refresh/${
              (
                head(getUniqueCalculationData) as ISandboxRefresh & {
                  GroupName: string;
                }
              ).ForecastDataRefreshID
            }`,
            'Status'
          );
          if (chartJsonData.Status === SubmissionStatus.Failed) {
            enqueueSnackbar(chartJsonData?.Status_Message, {
              variant: 'error',
            });
          } else if (chartJsonData.Status === SubmissionStatus.NoDataReturned) {
            enqueueSnackbar(chartJsonData?.Status_Message, {
              variant: 'warning',
            });
          } else if (chartJsonData.Status === SubmissionStatus.Finished) {
            const chartData = reduce(
              chartJsonData.data,
              (
                a: Record<string, IChartData>,
                c: IChartData & { ChartName: string }
              ) => {
                a[c.ChartName] = omit(c, 'ChartName');
                return a;
              },
              {}
            );
            enqueueSnackbar(
              `Sandbox Chart generated successfully. ${
                chartData[state.selectedChartTab].PublishedData.length >
                portfolioNumScenarios
                  ? `For readability, only the first ${portfolioNumScenarios} records are visualized.`
                  : ''
              }`,
              { variant: 'success' }
            );
            const chartJson: IChartData = {
              ...chartData[state.selectedChartTab],
              Dates: chartData[state.selectedChartTab].Dates,
              PublishedData: map(
                chartData[state.selectedChartTab].PublishedData.slice(
                  0,
                  portfolioNumScenarios
                ),
                (_) => ({ ..._, visible: true })
              ),
              PreviewData: map(
                chartData[state.selectedChartTab].PreviewData.slice(
                  0,
                  portfolioNumScenarios
                ),
                (_) => ({ ..._, visible: true })
              ),
            };
            const newState: Partial<IOutputsPanelState> = {
              chartData: {
                ...state.chartData,
                [state.selectedChartTab]: chartJson,
              },
              selectedChartRange: {
                ...state.selectedChartRange,
                [state.selectedChartTab]: [
                  chartJson.Dates[0].Date,
                  chartJson.Dates[chartJson.Dates.length - 1].Date,
                ],
              },
              selectedChartRangeIndex: {
                ...state.selectedChartRangeIndex,
                [state.selectedChartTab]: [0, chartJson.Dates.length - 1],
              },
              isSandboxChangesDialogLoading: false,
              groupedSandboxRefreshes: [],
              refreshChart: {
                ...state.refreshChart,
                [state.selectedChartTab]: true,
              },
              isGenerateChartPanelOpen: {
                ...state.isGenerateChartPanelOpen,
                [state.selectedChartTab]: true,
              },
              isEditChartPanelOpen: {
                ...state.isEditChartPanelOpen,
                [state.selectedChartTab]: false,
              },
              isFormatChartPanelOpen: {
                ...state.isFormatChartPanelOpen,
                [state.selectedChartTab]: false,
              },
              refreshedChartData: {
                ...state.refreshedChartData,
                [state.selectedChartTab]: {
                  Dates: chartJson.Dates,
                  PublishedData: structuredClone(chartJson.PublishedData),
                  PreviewData: structuredClone(chartJson.PreviewData),
                },
              },
            };
            setState(newState, (prevState, nextState) => {
              if (!showScenarios) {
                editAssumptionsContext?.handleChartDataChange(nextState);
                editAssumptionsContext?.disableRecalculateButton();
              }
            });
          }
        } catch (e: any) {
          enqueueSnackbar(e, { variant: 'error' });
          setState(
            {
              isSandboxChangesDialogLoading: false,
              groupedSandboxRefreshes: [],
              isEditChartPanelOpen: {
                ...state.isEditChartPanelOpen,
                [state.selectedChartTab]: true,
              },
              isGenerateChartPanelOpen: {
                ...state.isGenerateChartPanelOpen,
                [state.selectedChartTab]: false,
              },
              isFormatChartPanelOpen: {
                ...state.isFormatChartPanelOpen,
                [state.selectedChartTab]: false,
              },
            },
            (prevState, nextState) => {
              if (!showScenarios) {
                editAssumptionsContext?.handleChartDataChange(nextState);
              }
            }
          );
        }
      }
    })();
  }, [state.groupedSandboxRefreshes]);

  const outputs = useMemo(
    () => filter(data.rowIdentifiers, { Type: 'Output' }),
    [modelInstanceId, data]
  );

  const dimensions = useMemo(
    () => filter(data.rowIdentifiers, { Type: 'Dimension' }),
    [modelInstanceId, data]
  );

  const handleCloseEditChartPanel = useCallback(() => {
    setState(
      {
        isEditChartPanelOpen: {
          ...state.isEditChartPanelOpen,
          [state.selectedChartTab]: false,
        },
        isGenerateChartPanelOpen: {
          ...state.isGenerateChartPanelOpen,
          [state.selectedChartTab]: true,
        },
      },
      (prevState, nextState) => {
        if (!showScenarios) {
          editAssumptionsContext?.handleChartDataChange(nextState);
        }
      }
    );
  }, [state.isEditChartPanelOpen, state.isGenerateChartPanelOpen]);

  const handleSaveEditChartPanel = useCallback(
    (data: {
      modelId?: Model['id'];
      modelInstanceId?: ModelInstance['id'];
      scenarios: Scenario['id'][];
      outputId: RowIdentifier['id'];
      timescale: Timescale;
      dimensionInstances: RowIdentifier[];
      aggregateFlag: boolean;
    }) => {
      setState(
        {
          modelId: {
            ...state.modelId,
            [state.selectedChartTab]: data.modelId,
          },
          modelInstanceId: {
            ...state.modelInstanceId,
            [state.selectedChartTab]: data.modelInstanceId,
          },
          scenarios: {
            ...state.scenarios,
            [state.selectedChartTab]: data.scenarios,
          },
          outputId: {
            ...state.outputId,
            [state.selectedChartTab]: data.outputId,
          },
          timescale: {
            ...state.timescale,
            [state.selectedChartTab]: data.timescale,
          },
          dimensionInstances: {
            ...state.dimensionInstances,
            [state.selectedChartTab]: data.dimensionInstances,
          },
          aggregateFlag: {
            ...state.aggregateFlag,
            [state.selectedChartTab]: data.aggregateFlag,
          },
        },
        (prevState, nextState) => {
          if (!showScenarios) {
            editAssumptionsContext?.handleChartDataChange(nextState);
          }
        }
      );
      generateChart(
        data.modelId as number,
        data.modelInstanceId as number,
        data.scenarios,
        data.aggregateFlag,
        data.outputId,
        data.timescale,
        data.dimensionInstances
      );
    },
    [
      state.modelId,
      state.modelInstanceId,
      state.scenarios,
      state.selectedChartTab,
      state.outputId,
      state.timescale,
      state.dimensionInstances,
      state.aggregateFlag,
    ]
  );

  const handleCloseFormatChartPanel = useCallback(() => {
    setState(
      {
        isFormatChartPanelOpen: {
          ...state.isFormatChartPanelOpen,
          [state.selectedChartTab]: false,
        },
        isGenerateChartPanelOpen: {
          ...state.isGenerateChartPanelOpen,
          [state.selectedChartTab]: true,
        },
      },
      (prevState, nextState) => {
        if (!showScenarios) {
          editAssumptionsContext?.handleChartDataChange(nextState);
        }
      }
    );
  }, [state.isEditChartPanelOpen, state.isGenerateChartPanelOpen]);

  useEffect(() => {
    if (!showScenarios) {
      if (
        localStorage.getItem('chartData') &&
        includes(location.pathname, '/outputs')
      ) {
        setState(JSON.parse(localStorage.getItem('chartData') || '{}'));
      }
    } else {
      setState(chartState);
    }

    return function cleanup() {
      localStorage.removeItem('chartData');
      localStorage.removeItem('assumptionsData');
      setState(chartState);
    };
  }, []);

  const generateChart = useDynamicCallback(
    async (
      modelId: Model['id'] | undefined,
      modelInstanceId: ModelInstance['id'] | undefined,
      scenarios: Scenario['id'][],
      aggregateFlag: boolean,
      outputId: RowIdentifier['id'] | undefined,
      timescale: Timescale | undefined,
      dimensionInstances: RowIdentifier[],
      chartTitle?: string,
      selectedMagnitudeTruncationValue?: number,
      chartYAxisTitle?: string,
      selectedDecimalPlacesValue?: number,
      selectedPrefixValue?: string,
      selectedSuffixValue?: string,
      chartDateType?: string
    ) => {
      if (
        !modelId ||
        !modelInstanceId ||
        !timescale ||
        !outputId ||
        isEmpty(dimensionInstances) ||
        isEmpty(scenarios)
      ) {
        enqueueSnackbar(
          'Please select at least one output, one dimension, one scenario and a timescale',
          {
            variant: 'error',
          }
        );
      } else {
        setState(
          {
            chartLoading: {
              ...state.chartLoading,
              [state.selectedChartTab]: true,
            },
            isEditChartPanelOpen: {
              ...state.isEditChartPanelOpen,
              [state.selectedChartTab]: false,
            },
            isGenerateChartPanelOpen: {
              ...state.isGenerateChartPanelOpen,
              [state.selectedChartTab]: false,
            },
            isFormatChartPanelOpen: {
              ...state.isFormatChartPanelOpen,
              [state.selectedChartTab]: false,
            },
            selectedChartRange: {
              ...state.selectedChartRange,
              [state.selectedChartTab]: null,
            },
          },
          (prevState, nextState) => {
            if (!showScenarios) {
              editAssumptionsContext?.handleChartDataChange(nextState);
            }
          }
        );

        if (
          !isEmpty(
            get(editAssumptionsContext, 'assumptionsData', []).filter(
              (i) => i.isDirty
            )
          ) ||
          localStorage.getItem('assumptionsData')
        ) {
          setState(
            {
              isSandboxChangesDialogLoading: true,
            },
            (prevState, nextState) => {
              if (!showScenarios) {
                editAssumptionsContext?.handleChartDataChange(nextState);
              }
            }
          );
          const sandboxData: any[] = await API.create(
            WEB_ASSUMPTIONS_SANDBOX_PREVIEW(
              data?.modelInstance?.id as number,
              scenarios?.[0] as number
            ),
            {
              Assumptions: !isEmpty(
                get(editAssumptionsContext, 'assumptionsData', []).filter(
                  (i) => i.isDirty
                )
              )
                ? get(editAssumptionsContext, 'assumptionsData', []).filter(
                    (i) => i.isDirty
                  )
                : JSON.parse(localStorage.assumptionsData),
              Outputs: [
                {
                  ChartName: state.selectedChartTab,
                  RowIdentifierIds: map(dimensionInstances, 'id'),
                  TimeScale: timescale,
                  AggregateFlag: aggregateFlag,
                  OutputName: (
                    find(outputs, {
                      id: outputId,
                    }) as RowIdentifier
                  ).Name,
                },
              ],
            }
          );

          const groupsData = await API.load(
            `/instances/${data?.modelInstance?.id}/row_identifiers`
          );

          const yAxisTitle = (
            find(groupsData, {
              id: outputId,
            }) as RowIdentifier
          ).Name;
          const formatKey = (
            find(filter(groupsData, { Type: 'Output' }), {
              id: outputId,
            }) as RowIdentifier
          ).FormatKey as number;

          setState(
            {
              groupedSandboxRefreshes: map(sandboxData, (i) => {
                return {
                  ...i,
                  GroupName: find(groupsData, { id: i.RowIdentifierID })?.Name,
                };
              }),
              displaySandboxRefreshPanel: true,
            },
            (prevState, nextState) => {
              if (!showScenarios) {
                editAssumptionsContext?.handleChartDataChange(nextState);
              }
            }
          );

          const forecastRefreshId = sandboxData[0].ForecastDataRefreshID;

          const pollApi = async (): Promise<ISandboxRefresh[]> => {
            return await API.load(
              `/calculation_invocations/forecast_data_refreshes/${forecastRefreshId}/calculation_invocations`
            );
          };

          const validateData = (
            data: (ISandboxRefresh & { GroupName: string })[]
          ) => {
            // Check if all status properties are 2 or -1
            const valid = data.every(
              (obj) =>
                obj.Status === SubmissionStatus.Finished ||
                obj.Status === SubmissionStatus.Failed ||
                obj.Status === SubmissionStatus.NoDataReturned
            );

            if (valid) {
              return data;
            } else {
              return null;
            }
          };

          const poll = async (
            fetchFunction: Function,
            validateFunction: Function,
            interval: number
          ): Promise<ISandboxRefresh[] | null> => {
            return new Promise(async (resolve) => {
              const intervalId = setInterval(async () => {
                const result = await fetchFunction();
                setState(
                  {
                    groupedSandboxRefreshes: map(result, (i) => {
                      return {
                        ...i,
                        GroupName: find(groupsData, { id: i.RowIdentifierID })
                          ?.Name,
                      };
                    }),
                  },
                  (prevState, nextState) => {
                    if (!showScenarios) {
                      editAssumptionsContext?.handleChartDataChange(nextState);
                    }
                  }
                );
                const validatedResult = validateFunction(result);
                if (validatedResult !== null) {
                  clearInterval(intervalId);
                  resolve(validatedResult);
                }
              }, interval);
            });
          };

          const startPolling = async (): Promise<void> => {
            const data = await poll(pollApi, validateData, 2500);
            
            if (data !== null && data.length > 0) {
              let InvalidInputs: any[] = [];
              data.forEach((i: ISandboxRefresh): void => {
                  if (i.Status === SubmissionStatus.Failed) {
                    let invalidInputs = i.Status_Message
                      ? JSON.parse(i.Status_Message)
                      : [];
                    if (invalidInputs.length > 0) {
                      InvalidInputs = InvalidInputs.concat(invalidInputs);
                      enqueueSnackbar(errorMessage.DATE_LIKE_INPUT_ERROR_MESSAGE, {
                        variant: 'error',
                      })
                      return;
                    } else {
                      enqueueSnackbar(i.Status_Message, {
                        variant: 'error',
                      });
                    }
                  }
              });
              setInvalidInputs?.(InvalidInputs);
            }

            setState(
              {
                chartYAxisTitle: {
                  ...state.chartYAxisTitle,
                  [state.selectedChartTab]: chartYAxisTitle
                    ? chartYAxisTitle
                    : yAxisTitle,
                },
                chartTitle: {
                  ...state.chartTitle,
                  [state.selectedChartTab]: chartTitle
                    ? chartTitle
                    : capitalize(state.selectedChartTab),
                },
                chartFormatKey: {
                  ...state.chartFormatKey,
                  [state.selectedChartTab]: formatKey,
                },
                chartLoading: {
                  ...state.chartLoading,
                  [state.selectedChartTab]: false,
                },
                chartDateType: {
                  ...state.chartDateType,
                  [state.selectedChartTab]: chartDateType
                    ? chartDateType
                    : includes(
                          [Timescale.Weekly, Timescale.Monthly],
                          timescale as Timescale
                        )
                      ? (dateFormats(timescale as Timescale) as string[])[0]
                      : '',
                },
                selectedMagnitudeTruncationValue: {
                  ...state.selectedMagnitudeTruncationValue,
                  [state.selectedChartTab]: selectedMagnitudeTruncationValue
                    ? selectedMagnitudeTruncationValue
                    : ((
                        find(formatParameters, {
                          formatKey,
                        }) as IFormatOutputParameters
                      ).magnitudeTruncation as number),
                },
                selectedDecimalPlacesValue: {
                  ...state.selectedDecimalPlacesValue,
                  [state.selectedChartTab]: selectedDecimalPlacesValue
                    ? selectedDecimalPlacesValue
                    : ((
                        find(formatParameters, {
                          formatKey,
                        }) as IFormatOutputParameters
                      ).decimalPlaces as number),
                },
                selectedPrefixValue: {
                  ...state.selectedPrefixValue,
                  [state.selectedChartTab]: selectedPrefixValue
                    ? selectedPrefixValue
                    : (
                          find(formatParameters, {
                            formatKey,
                          }) as IFormatOutputParameters
                        ).numberType === '$'
                      ? '$'
                      : '',
                },
                selectedSuffixValue: {
                  ...state.selectedSuffixValue,
                  [state.selectedChartTab]: selectedSuffixValue
                    ? selectedSuffixValue
                    : (
                          find(formatParameters, {
                            formatKey,
                          }) as IFormatOutputParameters
                        ).numberType === 'Percent'
                      ? '%'
                      : '',
                },
                isEditChartPanelOpen: {
                  ...state.isEditChartPanelOpen,
                  [state.selectedChartTab]: false,
                },
                isGenerateChartPanelOpen: {
                  ...state.isGenerateChartPanelOpen,
                  [state.selectedChartTab]: false,
                },

                selectedChartType: {
                  ...state.selectedChartType,
                  [state.selectedChartTab]: chartTypes[0].id,
                },
                selectedChartDataType: {
                  ...state.selectedChartDataType,
                  [state.selectedChartTab]: chartDataTypes[0].id,
                },
                groupedSandboxRefreshes: map(data, (i) => {
                  return {
                    ...i,
                    GroupName: find(groupsData, { id: i.RowIdentifierID })
                      ?.Name,
                  };
                }),
              },
              (prevState, nextState) => {
                if (!showScenarios) {
                  editAssumptionsContext?.handleChartDataChange(nextState);
                }
              }
            );
            // Update state with data
          };

          await startPolling();
        } else {
          try {
            const chartData = await API.create<GeneratedFile>(
              '/chart_refresh/custom',
              {
                ModelInstanceID: modelInstanceId,
                ScenarioIDs: scenarios,
                RowIdentifierIDs: [...map(dimensionInstances, 'id'), outputId],
                Timescales: [timescale],
                Aggregate_Flag: aggregateFlag ? 1 : 0,
              }
            );
            if (chartData.Status === SubmissionStatus.Queued) {
              const chartJsonData = await getDataAtIntervals(
                `/chart_refresh/${chartData.id}`,
                'Status'
              );
              if (chartJsonData.Status === SubmissionStatus.Failed) {
                enqueueSnackbar(chartJsonData.Status_Message, {
                  variant: 'error',
                });
                setState(
                  {
                    refreshChart: {
                      ...state.refreshChart,
                      [state.selectedChartTab]: false,
                    },
                    chartLoading: {
                      ...state.chartLoading,
                      [state.selectedChartTab]: false,
                    },
                    isEditChartPanelOpen: {
                      ...state.isEditChartPanelOpen,
                      [state.selectedChartTab]: true,
                    },
                    isGenerateChartPanelOpen: {
                      ...state.isGenerateChartPanelOpen,
                      [state.selectedChartTab]: false,
                    },
                    isFormatChartPanelOpen: {
                      ...state.isFormatChartPanelOpen,
                      [state.selectedChartTab]: false,
                    },
                  },
                  (prevState, nextState) => {
                    if (!showScenarios) {
                      editAssumptionsContext?.handleChartDataChange(nextState);
                    }
                  }
                );
              } else if (
                chartJsonData.Status === SubmissionStatus.NoDataReturned
              ) {
                enqueueSnackbar(chartJsonData.Status_Message, {
                  variant: 'warning',
                });
                setState(
                  {
                    refreshChart: {
                      ...state.refreshChart,
                      [state.selectedChartTab]: false,
                    },
                    chartLoading: {
                      ...state.chartLoading,
                      [state.selectedChartTab]: false,
                    },
                    isEditChartPanelOpen: {
                      ...state.isEditChartPanelOpen,
                      [state.selectedChartTab]: true,
                    },
                    isGenerateChartPanelOpen: {
                      ...state.isGenerateChartPanelOpen,
                      [state.selectedChartTab]: false,
                    },
                    isFormatChartPanelOpen: {
                      ...state.isFormatChartPanelOpen,
                      [state.selectedChartTab]: false,
                    },
                  },
                  (prevState, nextState) => {
                    if (!showScenarios) {
                      editAssumptionsContext?.handleChartDataChange(nextState);
                    }
                  }
                );
              } else if (chartJsonData.Status === SubmissionStatus.Finished) {
                enqueueSnackbar(
                  `Chart generated successfully. ${
                    chartJsonData.data.PublishedData.length >
                    portfolioNumScenarios
                      ? `For readability, only the first ${portfolioNumScenarios} records are visualized.`
                      : ''
                  }`,
                  { variant: 'success' }
                );
                const rowIdentifiers = await API.load(
                  `/instances/${modelInstanceId}/row_identifiers`
                );
                const yAxisTitle = (
                  find(rowIdentifiers, {
                    id: outputId,
                  }) as RowIdentifier
                ).Name;
                const formatKey = (
                  find(filter(rowIdentifiers, { Type: 'Output' }), {
                    id: outputId,
                  }) as RowIdentifier
                ).FormatKey as number;
                const chartJson: IChartData = {
                  ...chartJsonData.data,
                  Dates: chartJsonData.data.Dates,
                  PublishedData: map(
                    chartJsonData.data.PublishedData.slice(
                      0,
                      portfolioNumScenarios
                    ),
                    (_) => ({ ..._, visible: true })
                  ),
                  PreviewData: [],
                };
                const newState: Partial<IOutputsPanelState> = {
                  chartYAxisTitle: {
                    ...state.chartYAxisTitle,
                    [state.selectedChartTab]: chartYAxisTitle
                      ? chartYAxisTitle
                      : yAxisTitle,
                  },
                  chartTitle: {
                    ...state.chartTitle,
                    [state.selectedChartTab]: chartTitle
                      ? chartTitle
                      : capitalize(state.selectedChartTab),
                  },
                  chartData: {
                    ...state.chartData,
                    [state.selectedChartTab]: chartJson,
                  },
                  chartFormatKey: {
                    ...state.chartFormatKey,
                    [state.selectedChartTab]: formatKey,
                  },
                  refreshedChartData: {
                    ...state.refreshedChartData,
                    [state.selectedChartTab]: {
                      Dates: chartJson.Dates,
                      PublishedData: structuredClone(chartJson.PublishedData),
                      PreviewData: [],
                    },
                  },
                  refreshChart: {
                    ...state.refreshChart,
                    [state.selectedChartTab]: true,
                  },
                  chartLoading: {
                    ...state.chartLoading,
                    [state.selectedChartTab]: false,
                  },
                  chartDateType: {
                    ...state.chartDateType,
                    [state.selectedChartTab]: chartDateType
                      ? chartDateType
                      : includes(
                            [Timescale.Weekly, Timescale.Monthly],
                            timescale as Timescale
                          )
                        ? (dateFormats(timescale as Timescale) as string[])[0]
                        : '',
                  },
                  selectedMagnitudeTruncationValue: {
                    ...state.selectedMagnitudeTruncationValue,
                    [state.selectedChartTab]: selectedMagnitudeTruncationValue
                      ? selectedMagnitudeTruncationValue
                      : ((
                          find(formatParameters, {
                            formatKey,
                          }) as IFormatOutputParameters
                        ).magnitudeTruncation as number),
                  },
                  selectedDecimalPlacesValue: {
                    ...state.selectedDecimalPlacesValue,
                    [state.selectedChartTab]: selectedDecimalPlacesValue
                      ? selectedDecimalPlacesValue
                      : ((
                          find(formatParameters, {
                            formatKey,
                          }) as IFormatOutputParameters
                        ).decimalPlaces as number),
                  },
                  selectedPrefixValue: {
                    ...state.selectedPrefixValue,
                    [state.selectedChartTab]: selectedPrefixValue
                      ? selectedPrefixValue
                      : (
                            find(formatParameters, {
                              formatKey,
                            }) as IFormatOutputParameters
                          ).numberType === '$'
                        ? '$'
                        : '',
                  },
                  selectedSuffixValue: {
                    ...state.selectedSuffixValue,
                    [state.selectedChartTab]: selectedSuffixValue
                      ? selectedSuffixValue
                      : (
                            find(formatParameters, {
                              formatKey,
                            }) as IFormatOutputParameters
                          ).numberType === 'Percent'
                        ? '%'
                        : '',
                  },
                  isEditChartPanelOpen: {
                    ...state.isEditChartPanelOpen,
                    [state.selectedChartTab]: false,
                  },
                  isGenerateChartPanelOpen: {
                    ...state.isGenerateChartPanelOpen,
                    [state.selectedChartTab]: true,
                  },
                  selectedChartRange: {
                    ...state.selectedChartRange,
                    [state.selectedChartTab]: [
                      chartJson.Dates[0].Date,
                      chartJson.Dates[chartJson.Dates.length - 1].Date,
                    ],
                  },
                  selectedChartRangeIndex: {
                    ...state.selectedChartRangeIndex,
                    [state.selectedChartTab]: [0, chartJson.Dates.length - 1],
                  },
                  selectedChartType: {
                    ...state.selectedChartType,
                    [state.selectedChartTab]: chartTypes[0].id,
                  },
                  selectedChartDataType: {
                    ...state.selectedChartDataType,
                    [state.selectedChartTab]: chartDataTypes[0].id,
                  },
                };
                setState(newState, (prevState, nextState) => {
                  if (!showScenarios) {
                    editAssumptionsContext?.handleChartDataChange(nextState);
                  }
                });
              }
            }
          } catch (e: any) {
            enqueueSnackbar(get(e, 'body.message', e), {
              variant: 'error',
            });
            setState(
              {
                chartLoading: {
                  ...state.chartLoading,
                  [state.selectedChartTab]: false,
                },
                isEditChartPanelOpen: {
                  ...state.isEditChartPanelOpen,
                  [state.selectedChartTab]: true,
                },
                isGenerateChartPanelOpen: {
                  ...state.isGenerateChartPanelOpen,
                  [state.selectedChartTab]: false,
                },
                isFormatChartPanelOpen: {
                  ...state.isFormatChartPanelOpen,
                  [state.selectedChartTab]: false,
                },
              },
              (prevState, nextState) => {
                if (!showScenarios) {
                  editAssumptionsContext?.handleChartDataChange(nextState);
                }
              }
            );
          }
        }
      }
    }
  );

  const selectedDimensionInstances = useMemo(
    () =>
      reduce(
        state.dimensionInstances[state.selectedChartTab],
        (a: Record<string, RowIdentifier[]>, c, idx, arr) => {
          const findDimensionByDimNumber = find(dimensions, {
            DimNumber: c.DimNumber,
          }) as RowIdentifier;
          a[findDimensionByDimNumber?.Name] = filter(arr, {
            DimNumber: get(findDimensionByDimNumber, 'DimNumber'),
          });
          return a;
        },
        {}
      ),
    [state.dimensionInstances, state.selectedChartTab, dimensions]
  );

  const editChartTabTitle = useCallback(() => {
    setState(
      {
        showEditChartTitleModal: {
          ...state.showEditChartTitleModal,
          [state.selectedChartTab]: true,
        },
      },
      (prevState, nextState) => {
        if (!showScenarios) {
          editAssumptionsContext?.handleChartDataChange(nextState);
        }
      }
    );
  }, [state.showEditChartTitleModal, state.selectedChartTab]);

  const removeChart = useCallback(
    (eventKey: IChartTab['eventKey']) => {
      setState(
        {
          showRemoveChartModal: eventKey,
        },
        (prevState, nextState) => {
          if (!showScenarios) {
            editAssumptionsContext?.handleChartDataChange(nextState);
          }
        }
      );
    },
    [state.showEditChartTitleModal]
  );

  const deleteChartTab = useCallback(
    (eventKey: IChartTab['eventKey']): void => {
      const tabsListClone = structuredClone(state.chartTabs);
      const tabsList = reject(tabsListClone, { eventKey });
      if (!isEmpty(tabsList)) {
        setState(
          {
            ...filterObject(state, eventKey),
            chartTabs: tabsList,
            selectedChartTab: tabsList[0].eventKey,
            isEditChartPanelOpen: {
              ...state.isEditChartPanelOpen,
              [state.selectedChartTab]: false,
            },
            isGenerateChartPanelOpen: {
              ...state.isGenerateChartPanelOpen,
              [state.selectedChartTab]: true,
            },
            isFormatChartPanelOpen: {
              ...state.isFormatChartPanelOpen,
              [state.selectedChartTab]: false,
            },
            showRemoveChartModal: '',
          },
          (prevState, nextState) => {
            if (!showScenarios) {
              editAssumptionsContext?.handleChartDataChange(nextState);
            }
          }
        );
        return;
      } else {
        setState(
          {
            ...state,
            ...chartState,
            isEditChartPanelOpen: {
              chart1: true,
            },
            isGenerateChartPanelOpen: {
              chart1: false,
            },
            isFormatChartPanelOpen: {
              chart1: false,
            },
            showRemoveChartModal: '',
          },
          (prevState, nextState) => {
            if (!showScenarios) {
              localStorage.setItem('chartData', JSON.stringify(nextState));
              editAssumptionsContext?.handleChartDataChange(nextState);
            }
          }
        );
        return;
      }
    },
    [state]
  );

  const addNewChartTab = useCallback(() => {
    const tabKeys = map(state.chartTabs, (tab) =>
      parseInt(replace(tab.eventKey, /[^0-9]/g, ''))
    );
    const maxEventKey = Math.max(...tabKeys);
    const newTab = concat(state.chartTabs, {
      eventKey: `chart${maxEventKey + 1}`,
      title: `Chart ${maxEventKey + 1}`,
    });
    setState(
      {
        selectedChartTab: `chart${maxEventKey + 1}`,
        chartTabs: newTab,
        modelId: {
          ...state.modelId,
          [`chart${maxEventKey + 1}`]: !showScenarios
            ? Number(searchParams.get('model'))
            : undefined,
        },
        modelInstanceId: {
          ...state.modelInstanceId,
          [`chart${maxEventKey + 1}`]: !showScenarios
            ? Number(searchParams.get('instance'))
            : undefined,
        },
        scenarios: {
          ...state.scenarios,
          [`chart${maxEventKey + 1}`]: !showScenarios
            ? [Number(searchParams.get('scenario'))]
            : [],
        },

        outputId: {
          ...state.outputId,
          [`chart${maxEventKey + 1}`]: undefined,
        },
        timescale: {
          ...state.timescale,
          [`chart${maxEventKey + 1}`]: undefined,
        },
        dimensionInstances: {
          ...state.dimensionInstances,
          [`chart${maxEventKey + 1}`]: [],
        },
        refreshChart: {
          ...state.refreshChart,
          [`chart${maxEventKey + 1}`]: false,
        },
        showEditChartTitleModal: {
          ...state.showEditChartTitleModal,
          [`chart${maxEventKey + 1}`]: false,
        },
        chartLoading: {
          ...state.chartLoading,
          [`chart${maxEventKey + 1}`]: false,
        },
        aggregateFlag: {
          ...state.aggregateFlag,
          [`chart${maxEventKey + 1}`]: true,
        },
        selectedSuffixValue: {
          ...state.selectedSuffixValue,
          [`chart${maxEventKey + 1}`]: '',
        },
        selectedPrefixValue: {
          ...state.selectedPrefixValue,
          [`chart${maxEventKey + 1}`]: '',
        },
        selectedDecimalPlacesValue: {
          ...state.selectedDecimalPlacesValue,
          [`chart${maxEventKey + 1}`]: 0,
        },
        selectedMagnitudeTruncationValue: {
          ...state.selectedMagnitudeTruncationValue,
          [`chart${maxEventKey + 1}`]: 0,
        },
        chartTitle: {
          ...state.chartTitle,
          [`chart${maxEventKey + 1}`]: '',
        },
        chartFormatKey: {
          ...state.chartFormatKey,
          [`chart${maxEventKey + 1}`]: undefined,
        },
        chartDateType: {
          ...state.chartDateType,
          [`chart${maxEventKey + 1}`]: '',
        },
        chartYAxisTitle: {
          ...state.chartYAxisTitle,
          [`chart${maxEventKey + 1}`]: '',
        },
        chartData: {
          ...state.chartData,
          [`chart${maxEventKey + 1}`]: {
            Dates: [],
            PublishedData: [],
            PreviewData: [],
          },
        },
        refreshedChartData: {
          ...state.refreshedChartData,
          [`chart${maxEventKey + 1}`]: {
            Dates: [],
            PublishedData: [],
            PreviewData: [],
          },
        },
        selectedChartRange: {
          ...state.selectedChartRange,
          [`chart${maxEventKey + 1}`]: null,
        },
        selectedChartRangeIndex: {
          ...state.selectedChartRangeIndex,
          [`chart${maxEventKey + 1}`]: undefined,
        },
        selectedChartType: {
          ...state.selectedChartType,
          [`chart${maxEventKey + 1}`]: chartTypes[0].id,
        },
        selectedChartDataType: {
          ...state.selectedChartDataType,
          [`chart${maxEventKey + 1}`]: chartDataTypes[0].id,
        },
        isEditChartPanelOpen: {
          ...state.isEditChartPanelOpen,
          [`chart${maxEventKey + 1}`]: true,
        },
        isGenerateChartPanelOpen: {
          ...state.isGenerateChartPanelOpen,
          [`chart${maxEventKey + 1}`]: false,
        },
        isFormatChartPanelOpen: {
          ...state.isFormatChartPanelOpen,
          [`chart${maxEventKey + 1}`]: false,
        },
      },
      (prevState, nextState) => {
        if (!showScenarios) {
          editAssumptionsContext?.handleChartDataChange(nextState);
        }
      }
    );
  }, [state]);

  const handleSaveEditChartTitle = useCallback(
    (editedChartTitle: string): void => {
      const newTabs = structuredClone(state.chartTabs);
      forEach(newTabs, (tab: { eventKey: string; title: string }) => {
        if (tab.eventKey === state.selectedChartTab) {
          tab.title = editedChartTitle
            ? editedChartTitle
            : upperFirst(lowerCase(state.selectedChartTab));
        }
      });
      setState(
        {
          chartTabs: newTabs,
        },
        (prevState, nextState) => {
          if (!showScenarios) {
            editAssumptionsContext?.handleChartDataChange(nextState);
          }
        }
      );
    },
    [state.selectedChartTab, state.chartTabs]
  );

  const handleSaveFormatChartPanel = useCallback(
    (data: {
      dateFormat: string;
      truncation: number;
      decimalPlaces: number;
      yAxisTitle: string;
      prefix: string;
      suffix: string;
    }) => {
      setState(
        {
          chartDateType: {
            ...state.chartDateType,
            [state.selectedChartTab]: data.dateFormat,
          },
          selectedMagnitudeTruncationValue: {
            ...state.selectedMagnitudeTruncationValue,
            [state.selectedChartTab]: data.truncation,
          },
          selectedDecimalPlacesValue: {
            ...state.selectedDecimalPlacesValue,
            [state.selectedChartTab]: data.decimalPlaces,
          },
          chartYAxisTitle: {
            ...state.chartYAxisTitle,
            [state.selectedChartTab]: data.yAxisTitle,
          },
          selectedPrefixValue: {
            ...state.selectedPrefixValue,
            [state.selectedChartTab]: data.prefix,
          },
          selectedSuffixValue: {
            ...state.selectedSuffixValue,
            [state.selectedChartTab]: data.suffix,
          },
          isGenerateChartPanelOpen: {
            ...state.isGenerateChartPanelOpen,
            [state.selectedChartTab]: true,
          },
          isFormatChartPanelOpen: {
            ...state.isFormatChartPanelOpen,
            [state.selectedChartTab]: false,
          },
        },
        (prevState, nextState) => {
          if (!showScenarios) {
            editAssumptionsContext?.handleChartDataChange(nextState);
          }
        }
      );
    },
    [state]
  );

  const cloneChartTab = (): void => {
    const tabKeys = map(state.chartTabs, (tab) =>
      parseInt(replace(tab.eventKey, /[^0-9]/g, ''))
    );
    const maxEventKey = Math.max(...tabKeys);
    const newTab = concat(state.chartTabs, {
      eventKey: `chart${maxEventKey + 1}`,
      title: `Chart ${maxEventKey + 1}`,
    });
    setState(
      {
        selectedChartTab: `chart${maxEventKey + 1}`,
        chartTabs: newTab,
        modelId: {
          ...state.modelId,
          [`chart${maxEventKey + 1}`]: state.modelId[state.selectedChartTab],
        },
        modelInstanceId: {
          ...state.modelInstanceId,
          [`chart${maxEventKey + 1}`]:
            state.modelInstanceId[state.selectedChartTab],
        },
        outputId: {
          ...state.outputId,
          [`chart${maxEventKey + 1}`]: state.outputId[state.selectedChartTab],
        },
        timescale: {
          ...state.timescale,
          [`chart${maxEventKey + 1}`]: state.timescale[state.selectedChartTab],
        },
        dimensionInstances: {
          ...state.dimensionInstances,
          [`chart${maxEventKey + 1}`]:
            state.dimensionInstances[state.selectedChartTab],
        },
        scenarios: {
          ...state.scenarios,
          [`chart${maxEventKey + 1}`]: state.scenarios[state.selectedChartTab],
        },
        refreshChart: {
          ...state.refreshChart,
          [`chart${maxEventKey + 1}`]:
            state.refreshChart[state.selectedChartTab],
        },
        chartData: {
          ...state.chartData,
          [`chart${maxEventKey + 1}`]: state.chartData[state.selectedChartTab],
        },
        refreshedChartData: {
          ...state.refreshedChartData,
          [`chart${maxEventKey + 1}`]:
            state.refreshedChartData[state.selectedChartTab],
        },
        showEditChartTitleModal: {
          ...state.showEditChartTitleModal,
          [`chart${maxEventKey + 1}`]:
            state.showEditChartTitleModal[state.selectedChartTab],
        },
        selectedMagnitudeTruncationValue: {
          ...state.selectedMagnitudeTruncationValue,
          [`chart${maxEventKey + 1}`]:
            state.selectedMagnitudeTruncationValue[state.selectedChartTab],
        },
        selectedDecimalPlacesValue: {
          ...state.selectedDecimalPlacesValue,
          [`chart${maxEventKey + 1}`]:
            state.selectedDecimalPlacesValue[state.selectedChartTab],
        },
        selectedPrefixValue: {
          ...state.selectedPrefixValue,
          [`chart${maxEventKey + 1}`]:
            state.selectedPrefixValue[state.selectedChartTab],
        },
        selectedSuffixValue: {
          ...state.selectedSuffixValue,
          [`chart${maxEventKey + 1}`]:
            state.selectedSuffixValue[state.selectedChartTab],
        },
        chartTitle: {
          ...state.chartTitle,
          [`chart${maxEventKey + 1}`]: state.chartTitle[state.selectedChartTab],
        },
        chartDateType: {
          ...state.chartDateType,
          [`chart${maxEventKey + 1}`]:
            state.chartDateType[state.selectedChartTab],
        },
        aggregateFlag: {
          ...state.aggregateFlag,
          [`chart${maxEventKey + 1}`]:
            state.aggregateFlag[state.selectedChartTab],
        },
        chartLoading: {
          ...state.chartLoading,
          [`chart${maxEventKey + 1}`]:
            state.chartLoading[state.selectedChartTab],
        },
        chartFormatKey: {
          ...state.chartFormatKey,
          [`chart${maxEventKey + 1}`]:
            state.chartFormatKey[state.selectedChartTab],
        },
        chartYAxisTitle: {
          ...state.chartYAxisTitle,
          [`chart${maxEventKey + 1}`]:
            state.chartYAxisTitle[state.selectedChartTab],
        },
        selectedChartRange: {
          ...state.selectedChartRange,
          [`chart${maxEventKey + 1}`]:
            state.selectedChartRange[state.selectedChartTab],
        },
        selectedChartRangeIndex: {
          ...state.selectedChartRangeIndex,
          [`chart${maxEventKey + 1}`]:
            state.selectedChartRangeIndex[state.selectedChartTab],
        },
        selectedChartType: {
          ...state.selectedChartType,
          [`chart${maxEventKey + 1}`]:
            state.selectedChartType[state.selectedChartTab],
        },
        selectedChartDataType: {
          ...state.selectedChartDataType,
          [`chart${maxEventKey + 1}`]:
            state.selectedChartDataType[state.selectedChartTab],
        },
        isEditChartPanelOpen: {
          ...state.isEditChartPanelOpen,
          [`chart${maxEventKey + 1}`]:
            state.isEditChartPanelOpen[state.selectedChartTab],
        },
        isGenerateChartPanelOpen: {
          ...state.isGenerateChartPanelOpen,
          [`chart${maxEventKey + 1}`]:
            state.isGenerateChartPanelOpen[state.selectedChartTab],
        },
        isFormatChartPanelOpen: {
          ...state.isFormatChartPanelOpen,
          [`chart${maxEventKey + 1}`]:
            state.isFormatChartPanelOpen[state.selectedChartTab],
        },
      },
      (prevState, nextState) => {
        if (!showScenarios) {
          editAssumptionsContext?.handleChartDataChange(nextState);
        }
      }
    );
  };

  const selectedChartTab = find(state.chartTabs, {
    eventKey: state.selectedChartTab,
  });

  const reorderChartTabs = (tabs: IChartTab[]) => {
    setState({ chartTabs: tabs }, (prevState, nextState) => {
      if (!showScenarios) {
        editAssumptionsContext?.handleChartDataChange(nextState);
      }
    });
  };

  const handleChangeChartRange = (
    startRange: string,
    endRange: string
  ): void => {
    if (
      startRange === head(state.selectedChartRange[state.selectedChartTab]) &&
      endRange === last(state.selectedChartRange[state.selectedChartTab])
    ) {
      return;
    }
    const startIndex = findIndex(
      state.refreshedChartData[state.selectedChartTab].Dates,
      (item) => item.Date === startRange
    );
    const endIndex = findIndex(
      state.refreshedChartData[state.selectedChartTab].Dates,
      (item) => item.Date === endRange
    );
    if (startIndex > endIndex) {
      enqueueSnackbar('Start date should be less than end date', {
        variant: 'error',
      });
    } else {
      const newChartDataSeries = {
        PublishedData: map(
          state.refreshedChartData[state.selectedChartTab].PublishedData,
          (item) => {
            return {
              id: item.id,
              scenario: item.scenario,
              segment: item.segment,
              name: item.name,
              values: slice(
                (
                  find(
                    state.refreshedChartData[state.selectedChartTab]
                      .PublishedData,
                    {
                      id: item.id,
                    }
                  ) as IChartDataSeries
                ).values,
                startIndex,
                endIndex + 1
              ),
              visible: item.visible,
            };
          }
        ),
        PreviewData: map(
          state.refreshedChartData[state.selectedChartTab].PreviewData,
          (item) => {
            return {
              id: item.id,
              scenario: item.scenario,
              segment: item.segment,
              name: item.name,
              values: slice(
                (
                  find(
                    state.refreshedChartData[state.selectedChartTab]
                      .PreviewData,
                    {
                      id: item.id,
                    }
                  ) as IChartDataSeries
                ).values,
                startIndex,
                endIndex + 1
              ),
              visible: item.visible,
            };
          }
        ),
      };
      const newChartDataDates = slice(
        state.refreshedChartData[state.selectedChartTab].Dates,
        startIndex,
        endIndex + 1
      );
      setState(
        {
          selectedChartRange: {
            ...state.selectedChartRange,
            [state.selectedChartTab]: [startRange, endRange],
          },
          chartData: {
            ...state.chartData,
            [state.selectedChartTab]: {
              Dates: newChartDataDates,
              PublishedData: structuredClone(newChartDataSeries.PublishedData),
              PreviewData: structuredClone(newChartDataSeries.PreviewData),
            },
          },
          selectedChartRangeIndex: {
            ...state.selectedChartRangeIndex,
            [state.selectedChartTab]: [startIndex, endIndex],
          },
        },
        (prevState, nextState) => {
          if (!showScenarios) {
            editAssumptionsContext?.handleChartDataChange(nextState);
          }
        }
      );
    }
  };

  const handleChangeChartType = (chartType: string): void => {
    setState(
      {
        selectedChartType: {
          ...state.selectedChartType,
          [state.selectedChartTab]: chartType,
        },
      },
      (prevState, nextState) => {
        if (!showScenarios) {
          editAssumptionsContext?.handleChartDataChange(nextState);
        }
      }
    );
  };

  const handleChangeChartDataType = (chartDataType: string): void => {
    setState(
      {
        selectedChartDataType: {
          ...state.selectedChartDataType,
          [state.selectedChartTab]: chartDataType,
        },
      },
      (prevState, nextState) => {
        if (!showScenarios) {
          editAssumptionsContext?.handleChartDataChange(nextState);
        }
      }
    );
  };

  const handleChangePanelCollapsedState = (): void => {
    setState(
      {
        isPanelCollapsed: {
          ...state.isPanelCollapsed,
          [state.selectedChartTab]:
            !state.isPanelCollapsed[state.selectedChartTab],
        },
      },
      (prevState, nextState) => {
        if (!showScenarios) {
          editAssumptionsContext?.handleChartDataChange(nextState);
        }
      }
    );
  };

  const handleLegendChange = (id: number, visible: boolean) => {
    setState(
      {
        chartData: {
          ...state.chartData,
          [state.selectedChartTab]: {
            Dates: state.chartData[state.selectedChartTab].Dates,
            PublishedData: map(
              state.chartData[state.selectedChartTab].PublishedData,
              (item) => {
                if (item.id === id) {
                  return {
                    ...item,
                    visible,
                  };
                }
                return item;
              }
            ),
            PreviewData: map(
              state.chartData[state.selectedChartTab].PreviewData,
              (item) => {
                if (item.id === id) {
                  return {
                    ...item,
                    visible,
                  };
                }
                return item;
              }
            ),
          },
        },
        refreshedChartData: {
          ...state.refreshedChartData,
          [state.selectedChartTab]: {
            Dates: state.refreshedChartData[state.selectedChartTab].Dates,
            PublishedData: map(
              state.refreshedChartData[state.selectedChartTab].PublishedData,
              (item) => {
                if (item.id === id) {
                  return {
                    ...item,
                    visible,
                  };
                }
                return item;
              }
            ),
            PreviewData: map(
              state.refreshedChartData[state.selectedChartTab].PreviewData,
              (item) => {
                if (item.id === id) {
                  return {
                    ...item,
                    visible,
                  };
                }
                return item;
              }
            ),
          },
        },
      },
      (prevState, nextState) => {
        if (!showScenarios) {
          editAssumptionsContext?.handleChartDataChange(nextState);
        }
      }
    );
  };

  return (
    <Box
      sx={
        inContainer ? {} : { width: '100%', height: '72vh', overflow: 'scroll' }
      }
    >
      <EditChartTitleDialog
        open={state.showEditChartTitleModal[state.selectedChartTab]}
        close={() => {
          setState(
            {
              showEditChartTitleModal: {
                ...state.showEditChartTitleModal,
                [state.selectedChartTab]: false,
              },
            },
            (prevState, nextState) => {
              if (!showScenarios) {
                editAssumptionsContext?.handleChartDataChange(nextState);
              }
            }
          );
        }}
        onSave={handleSaveEditChartTitle}
        chartTitle={get(
          find(state.chartTabs, [
            'eventKey',
            state.selectedChartTab,
          ]) as IChartTab,
          'title'
        )}
      />
      <RemoveChartDialog
        open={Boolean(state.showRemoveChartModal)}
        close={() => {
          setState(
            {
              showRemoveChartModal: '',
            },
            (prevState, nextState) => {
              if (!showScenarios) {
                editAssumptionsContext?.handleChartDataChange(nextState);
              }
            }
          );
        }}
        chartTabData={
          find(state.chartTabs, [
            'eventKey',
            state.showRemoveChartModal,
          ]) as IChartTab
        }
        onRemove={deleteChartTab}
      />
      <Box>
        {state.isSandboxChangesDialogLoading && (
          <Backdrop
            sx={{
              color: 'white',
              zIndex: (theme) => theme.zIndex.drawer + 3,
              backgroundColor: alpha('#596B7A', 0.7),
            }}
            open={state.isSandboxChangesDialogLoading}
          >
            <Stack direction="column" spacing={2} alignItems="center">
              {containsOnly(
                [SubmissionStatus.Started, SubmissionStatus.Queued],
                map(state.groupedSandboxRefreshes, 'Status')
              ) && <CircularProgress color="primary" />}
              <>
                {!isEmpty(map(state.groupedSandboxRefreshes, 'Status')) ? (
                  <>
                    {containsOnly(
                      [SubmissionStatus.Started, SubmissionStatus.Queued],
                      map(state.groupedSandboxRefreshes, 'Status')
                    ) && (
                      <Typography variant="h6">
                        Sandbox Calculation is in progress
                      </Typography>
                    )}
                    {containsOnly(
                      [SubmissionStatus.Finished],
                      map(state.groupedSandboxRefreshes, 'Status')
                    ) && (
                      <Typography variant="h6">
                        Sandbox Calculation successfully completed
                      </Typography>
                    )}
                  </>
                ) : (
                  <Typography variant="h6">
                    Sandbox Calculation is in progress
                  </Typography>
                )}
              </>
              {some(
                map(state.groupedSandboxRefreshes, 'Status'),
                (i) => i === SubmissionStatus.Failed
              ) && (
                <>
                  {some(
                    map(state.groupedSandboxRefreshes, 'Status_Message'),
                    (i) => i === 'Output request did not match any values.'
                  ) ? (
                    <Typography>
                      Sandbox calculation was successful. However, outputs
                      request did not match any values. Press Close to continue
                      editing.
                    </Typography>
                  ) : (
                    <Typography>
                      Sandbox calculation was not successful. Press Close to
                      continue editing.
                    </Typography>
                  )}
                </>
              )}
              {(() => {
                if (
                  containsOnly(
                    [SubmissionStatus.Finished],
                    map(state.groupedSandboxRefreshes, 'Status')
                  )
                ) {
                  return <Typography>Refreshing...</Typography>;
                } else if (
                  some(
                    map(state.groupedSandboxRefreshes, 'Status'),
                    (i) => i === SubmissionStatus.Failed
                  )
                ) {
                  return (
                    <Button
                      variant="contained"
                      onClick={() =>
                        setState(
                          {
                            displaySandboxRefreshPanel: false,
                            isSandboxChangesDialogLoading: false,
                            isEditChartPanelOpen: {
                              ...state.isEditChartPanelOpen,
                              [state.selectedChartTab]: true,
                            },
                            isGenerateChartPanelOpen: {
                              ...state.isGenerateChartPanelOpen,
                              [state.selectedChartTab]: false,
                            },
                            isFormatChartPanelOpen: {
                              ...state.isFormatChartPanelOpen,
                              [state.selectedChartTab]: false,
                            },
                            groupedSandboxRefreshes: [],
                          },
                          (prevState, nextState) => {
                            if (!showScenarios) {
                              editAssumptionsContext?.handleChartDataChange(
                                nextState
                              );
                            }
                          }
                        )
                      }
                    >
                      Close
                    </Button>
                  );
                } else if (
                  some(
                    map(state.groupedSandboxRefreshes, 'Status'),
                    (i) => i === SubmissionStatus.NoDataReturned
                  )
                ) {
                  enqueueSnackbar(
                    find(state.groupedSandboxRefreshes, {
                      Status: SubmissionStatus.NoDataReturned,
                    })?.Status_Message,
                    { variant: 'warning' }
                  );
                  setState(
                    {
                      displaySandboxRefreshPanel: false,
                      isSandboxChangesDialogLoading: false,
                      isEditChartPanelOpen: {
                        ...state.isEditChartPanelOpen,
                        [state.selectedChartTab]: true,
                      },
                      isGenerateChartPanelOpen: {
                        ...state.isGenerateChartPanelOpen,
                        [state.selectedChartTab]: false,
                      },
                      isFormatChartPanelOpen: {
                        ...state.isFormatChartPanelOpen,
                        [state.selectedChartTab]: false,
                      },
                      groupedSandboxRefreshes: [],
                    },
                    (prevState, nextState) => {
                      if (!showScenarios) {
                        editAssumptionsContext?.handleChartDataChange(
                          nextState
                        );
                      }
                    }
                  );
                  return null;
                }
              })()}

              {state.displaySandboxRefreshPanel &&
                !isEmpty(state.groupedSandboxRefreshes) && (
                  <FloatingPanelContainer>
                    <FloatingPanelHeaderContainer>
                      <FloatingPanelHeaderLayoutContainer>
                        <FloatingPanelHeaderLayoutTextContainer>
                          <Typography sx={{ lineHeight: 'inherit' }}>
                            Refreshing Sandbox Calculation
                          </Typography>
                        </FloatingPanelHeaderLayoutTextContainer>
                        <Box style={{ flex: '0 0 auto' }}>
                          <FloatingPanelHeaderLayoutTextIconsContainer>
                            <IconButton
                              sx={{
                                height: '40px',
                                width: '40px',
                                margin: '0 4px',
                              }}
                              onClick={() =>
                                setState(
                                  {
                                    isSandboxRefreshPanelExpanded:
                                      !state.isSandboxRefreshPanelExpanded,
                                  },
                                  (prevState, nextState) => {
                                    if (!showScenarios) {
                                      editAssumptionsContext?.handleChartDataChange(
                                        nextState
                                      );
                                    }
                                  }
                                )
                              }
                            >
                              {state.isSandboxRefreshPanelExpanded ? (
                                <ExpandMore sx={{ color: 'white' }} />
                              ) : (
                                <ExpandLess sx={{ color: 'white' }} />
                              )}
                            </IconButton>
                          </FloatingPanelHeaderLayoutTextIconsContainer>
                        </Box>
                      </FloatingPanelHeaderLayoutContainer>
                    </FloatingPanelHeaderContainer>
                    <Box>
                      <Collapse in={state.isSandboxRefreshPanelExpanded}>
                        <Box
                          sx={{
                            width: '100%',
                            bgcolor: 'background.paper',
                            border: `1px solid #323232`,
                          }}
                        >
                          <List
                            sx={{
                              width: '498px',
                              maxHeight: '300px',
                              overflow: 'auto',
                              overflowX: 'hidden',
                            }}
                          >
                            {map(state.groupedSandboxRefreshes, (i) => {
                              return (
                                <Fragment key={i.id}>
                                  <ListItem
                                    secondaryAction={
                                      <>
                                        {(() => {
                                          switch (i.Status) {
                                            case SubmissionStatus.Finished:
                                            case SubmissionStatus.NoDataReturned:
                                              return (
                                                <CheckCircle color="success" />
                                              );
                                            case SubmissionStatus.Failed:
                                              return (
                                                <Tooltip
                                                  title={
                                                    i.Status_Message &&
                                                    i.Status_Message.includes('InputRowID') &&
                                                    JSON.parse(i.Status_Message)
                                                      ?.length > 0
                                                      ? errorMessage.DATE_LIKE_INPUT_ERROR_MESSAGE
                                                      : i.Status_Message
                                                  }
                                                  arrow
                                                >
                                                  <Error color="error" />
                                                </Tooltip>
                                              );
                                            case SubmissionStatus.Started:
                                              return (
                                                <CircularProgress size={25} />
                                              );
                                            case SubmissionStatus.Queued:
                                              return (
                                                <CircularProgress size={25} />
                                              );
                                          }
                                        })()}
                                      </>
                                    }
                                  >
                                    <ListItemText>
                                      <Tooltip
                                        arrow
                                        title={
                                          i.GroupName ? i.GroupName : 'Status'
                                        }
                                      >
                                        <Typography
                                          sx={{ color: 'black' }}
                                          noWrap
                                        >
                                          {i.GroupName ? i.GroupName : 'Status'}
                                        </Typography>
                                      </Tooltip>
                                    </ListItemText>
                                  </ListItem>
                                  <Divider />
                                </Fragment>
                              );
                            })}
                          </List>
                        </Box>
                      </Collapse>
                    </Box>
                  </FloatingPanelContainer>
                )}
            </Stack>
          </Backdrop>
        )}
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Grid
            container
            sx={{ mt: 2, width: '100%', height: 'auto' }}
            alignItems="center"
          >
            <Grid xs={12}>
              <DraggableTabs<IChartTab>
                showAddButton={() => (
                  <Tooltip title="Add New Chart" arrow>
                    <IconButton
                      size="small"
                      disabled={
                        state.chartLoading[state.selectedChartTab] ||
                        !state.refreshChart[state.selectedChartTab] ||
                        state.chartTabs.length === 5
                      }
                      onClick={addNewChartTab}
                    >
                      <Add />
                    </IconButton>
                  </Tooltip>
                )}
                tabs={state.chartTabs}
                handleChangeTabs={reorderChartTabs}
                activeTab={findIndex(state.chartTabs, {
                  eventKey: state.selectedChartTab,
                })}
                setActiveTab={(index) => {
                  setState(
                    {
                      selectedChartTab: state.chartTabs[index].eventKey,
                    },
                    (prevState, nextState) => {
                      if (!showScenarios) {
                        editAssumptionsContext?.handleChartDataChange(
                          nextState
                        );
                      }
                    }
                  );
                }}
                renderLabel={(item) => (
                  <>
                    <Tooltip arrow title={item.title}>
                      <Typography>
                        {(() => {
                          if (item.title.length > 15) {
                            return item.title.slice(0, 15) + '...';
                          }
                          return item.title;
                        })()}
                      </Typography>
                    </Tooltip>
                    <Box>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          editChartTabTitle();
                        }}
                        disabled={item.eventKey !== state.selectedChartTab}
                        component="span"
                      >
                        <Edit fontSize="small" />
                      </IconButton>
                      <IconButton
                        size="small"
                        disabled={
                          state.chartLoading[state.selectedChartTab] ||
                          state.chartTabs.length === 5 ||
                          item.eventKey !== state.selectedChartTab ||
                          !state.refreshChart[state.selectedChartTab]
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          cloneChartTab();
                        }}
                        component="span"
                      >
                        <ContentCopy fontSize="small" />
                      </IconButton>
                      <IconButton
                        size="small"
                        disabled={state.chartLoading[state.selectedChartTab]}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          removeChart(item.eventKey);
                        }}
                        component="span"
                      >
                        <Close fontSize="small" />
                      </IconButton>
                    </Box>
                  </>
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <Grid
          container
          sx={{ my: 2, width: '100%', height: '100%' }}
          spacing={0}
        >
          <Grid
            xs={state.isPanelCollapsed[state.selectedChartTab] ? 0.75 : 2.5}
            sx={{
              transition: (theme) =>
                theme.transitions.create('all', {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen,
                }),
            }}
          >
            <Card
              sx={{
                height: '100%',
                mr: 2,
              }}
            >
              <CardContent
                sx={{
                  background: 'white',
                  '& hr': {
                    my: 1,
                  },
                }}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {!state.isPanelCollapsed[state.selectedChartTab] && (
                    <Grid sm={6}>
                      <Typography variant="h6">Chart Data</Typography>
                    </Grid>
                  )}
                  {state.refreshChart[state.selectedChartTab] && (
                    <Grid
                      sm={
                        state.isPanelCollapsed[state.selectedChartTab] ? 12 : 6
                      }
                      container
                      justifyContent={
                        state.isPanelCollapsed[state.selectedChartTab]
                          ? 'center'
                          : 'flex-end'
                      }
                    >
                      {!state.isPanelCollapsed[state.selectedChartTab] && (
                        <Tooltip title="Edit Chart" arrow>
                          <IconButton
                            onClick={() =>
                              setState(
                                {
                                  isEditChartPanelOpen: {
                                    ...state.isEditChartPanelOpen,
                                    [state.selectedChartTab]: true,
                                  },
                                  isGenerateChartPanelOpen: {
                                    ...state.isGenerateChartPanelOpen,
                                    [state.selectedChartTab]: false,
                                  },
                                  isFormatChartPanelOpen: {
                                    ...state.isFormatChartPanelOpen,
                                    [state.selectedChartTab]: false,
                                  },
                                },
                                (prevState, nextState) => {
                                  if (!showScenarios) {
                                    editAssumptionsContext?.handleChartDataChange(
                                      nextState
                                    );
                                  }
                                }
                              )
                            }
                          >
                            <Edit fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}
                      {!state.isPanelCollapsed[state.selectedChartTab] && (
                        <Tooltip title="Format Chart" arrow>
                          <IconButton
                            onClick={() =>
                              setState(
                                {
                                  isEditChartPanelOpen: {
                                    ...state.isEditChartPanelOpen,
                                    [state.selectedChartTab]: false,
                                  },
                                  isGenerateChartPanelOpen: {
                                    ...state.isGenerateChartPanelOpen,
                                    [state.selectedChartTab]: false,
                                  },
                                  isFormatChartPanelOpen: {
                                    ...state.isFormatChartPanelOpen,
                                    [state.selectedChartTab]: true,
                                  },
                                },
                                (prevState, nextState) => {
                                  if (!showScenarios) {
                                    editAssumptionsContext?.handleChartDataChange(
                                      nextState
                                    );
                                  }
                                }
                              )
                            }
                            disabled={
                              !state.refreshChart[state.selectedChartTab]
                            }
                          >
                            <InsertChart fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}
                      <IconButton
                        size="small"
                        onClick={handleChangePanelCollapsedState}
                      >
                        {state.isPanelCollapsed[state.selectedChartTab] ? (
                          <KeyboardArrowRight fontSize="small" />
                        ) : (
                          <KeyboardArrowLeft />
                        )}
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
                <Divider />
                {state.isPanelCollapsed[state.selectedChartTab] ? (
                  <Grid
                    container
                    direction="column"
                    sx={{
                      display: 'block',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    spacing={1}
                  >
                    <Grid xs={12} sx={{ textAlign: 'center', mt: 1 }}>
                      {!state.isEditChartPanelOpen[state.selectedChartTab] && (
                        <>
                          {state.isPanelCollapsed[state.selectedChartTab] && (
                            <Tooltip title="Edit Chart" arrow>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  setState(
                                    {
                                      isEditChartPanelOpen: {
                                        ...state.isEditChartPanelOpen,
                                        [state.selectedChartTab]: true,
                                      },
                                      isGenerateChartPanelOpen: {
                                        ...state.isGenerateChartPanelOpen,
                                        [state.selectedChartTab]: false,
                                      },
                                      isFormatChartPanelOpen: {
                                        ...state.isFormatChartPanelOpen,
                                        [state.selectedChartTab]: false,
                                      },
                                    },
                                    (prevState, nextState) => {
                                      if (!showScenarios) {
                                        editAssumptionsContext?.handleChartDataChange(
                                          nextState
                                        );
                                      }
                                    }
                                  )
                                }
                              >
                                <Edit fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </>
                      )}
                    </Grid>
                    <Grid xs={12} sx={{ textAlign: 'center', mt: 1 }}>
                      {state.isPanelCollapsed[state.selectedChartTab] && (
                        <Tooltip title="Format Chart" arrow>
                          <IconButton
                            size="small"
                            onClick={() =>
                              setState(
                                {
                                  isEditChartPanelOpen: {
                                    ...state.isEditChartPanelOpen,
                                    [state.selectedChartTab]: false,
                                  },
                                  isGenerateChartPanelOpen: {
                                    ...state.isGenerateChartPanelOpen,
                                    [state.selectedChartTab]: false,
                                  },
                                  isFormatChartPanelOpen: {
                                    ...state.isFormatChartPanelOpen,
                                    [state.selectedChartTab]: true,
                                  },
                                },
                                (prevState, nextState) => {
                                  if (!showScenarios) {
                                    editAssumptionsContext?.handleChartDataChange(
                                      nextState
                                    );
                                  }
                                }
                              )
                            }
                            disabled={
                              !state.refreshChart[state.selectedChartTab]
                            }
                          >
                            <InsertChart fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    {showScenarios && (
                      <>
                        <Grid container sx={{ mt: 2 }}>
                          <Grid xs={12}>
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: 'bold' }}
                            >
                              Model
                            </Typography>
                          </Grid>
                          <Grid xs={12}>
                            {state.modelId[state.selectedChartTab]
                              ? data?.model?.Name
                              : ''}
                          </Grid>
                        </Grid>
                        <Grid container sx={{ mt: 2 }}>
                          <Grid xs={12}>
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: 'bold' }}
                            >
                              Model Instance
                            </Typography>
                          </Grid>
                          <Grid xs={12}>
                            <Typography sx={{ wordBreak: 'break-all' }}>
                              {state.modelInstanceId[state.selectedChartTab]
                                ? data?.modelInstance?.Name
                                : ''}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container sx={{ mt: 2 }}>
                          <Grid xs={12}>
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: 'bold' }}
                            >
                              Scenarios
                            </Typography>
                          </Grid>
                          <Grid xs={12}>
                            {!isEmpty(state.scenarios[state.selectedChartTab])
                              ? map(
                                  filter(data?.scenarios, (s) =>
                                    includes(
                                      state.scenarios[state.selectedChartTab],
                                      s.id
                                    )
                                  ),
                                  (s) => (
                                    <Box
                                      key={s.id}
                                      sx={{ wordBreak: 'break-word' }}
                                    >
                                      {s.Name}
                                    </Box>
                                  )
                                )
                              : ''}
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <Grid container sx={{ mt: 2 }}>
                      <Grid xs={12}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                          Output
                        </Typography>
                      </Grid>
                      <Grid xs={12}>
                        {state.outputId
                          ? find(outputs, {
                              id: state.outputId[state.selectedChartTab],
                            })?.Name
                          : ''}
                      </Grid>
                    </Grid>
                    <Grid container sx={{ mt: 2 }}>
                      <Grid xs={12}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                          Timescale
                        </Typography>
                      </Grid>
                      <Grid xs={12}>
                        {state.timescale[state.selectedChartTab]}
                      </Grid>
                    </Grid>
                    <Grid container sx={{ mt: 2 }}>
                      <Grid xs={12}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                          Segments
                        </Typography>
                      </Grid>
                      <Grid xs={12}>
                        {map(keys(selectedDimensionInstances), (dimension) => {
                          return (
                            <Box key={dimension}>
                              <Typography
                                sx={{
                                  color: (t) => t.palette.primary.main,
                                  fontWeight: 'bold',
                                }}
                              >
                                {dimension}
                              </Typography>
                              {map(
                                selectedDimensionInstances[dimension],
                                (d) => (
                                  <Box key={d.id}>{d.Name}</Box>
                                )
                              )}
                            </Box>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid
            xs={state.isPanelCollapsed[state.selectedChartTab] ? 11.25 : 9.5}
            sx={{
              transition: (theme) =>
                theme.transitions.create('all', {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen,
                }),
            }}
          >
            <Card sx={{ background: 'white', height: '100%' }}>
              <CardContent
                sx={{
                  paddingBottom: 2,
                  marginBottom: 2,
                  '&:last-child': {
                    padding: 0,
                  },
                }}
              >
                <EditChartPanel
                  showScenarios={showScenarios}
                  open={state.isEditChartPanelOpen[state.selectedChartTab]}
                  modelInstance={data?.modelInstance as ModelInstance}
                  handleCloseEditChartPanel={handleCloseEditChartPanel}
                  handleSaveEditChartPanel={handleSaveEditChartPanel}
                  selectedDimensionInstances={
                    state.dimensionInstances[state.selectedChartTab]
                  }
                  selectedOutput={state.outputId[state.selectedChartTab]}
                  selectedModel={state.modelId[state.selectedChartTab]}
                  selectedModelInstance={
                    state.modelInstanceId[state.selectedChartTab]
                  }
                  selectedScenarios={state.scenarios[state.selectedChartTab]}
                  selectedTimescale={state.timescale[state.selectedChartTab]}
                  aggregateOutputs={state.aggregateFlag[state.selectedChartTab]}
                  refreshState={state.refreshChart[state.selectedChartTab]}
                />
                {state.chartLoading[state.selectedChartTab] && (
                  <FullscreenProgress />
                )}
                {state.isGenerateChartPanelOpen[state.selectedChartTab] && (
                  <GenerateChartPanel
                    isPanelCollapsed={
                      state.isPanelCollapsed[state.selectedChartTab]
                    }
                    refreshedChartData={
                      state.refreshedChartData[state.selectedChartTab]
                    }
                    chartData={state.chartData[state.selectedChartTab]}
                    timescale={
                      state.timescale[state.selectedChartTab] as Timescale
                    }
                    formatKey={
                      state.chartFormatKey[state.selectedChartTab] as number
                    }
                    yAxisTitle={state.chartYAxisTitle[state.selectedChartTab]}
                    chartTitle={selectedChartTab?.title as string}
                    chartDateType={state.chartDateType[state.selectedChartTab]}
                    magnitudeTruncation={
                      state.selectedMagnitudeTruncationValue[
                        state.selectedChartTab
                      ]
                    }
                    decimalPlaces={
                      state.selectedDecimalPlacesValue[state.selectedChartTab]
                    }
                    prefix={state.selectedPrefixValue[state.selectedChartTab]}
                    suffix={state.selectedSuffixValue[state.selectedChartTab]}
                    handleChangeChartRange={handleChangeChartRange}
                    selectedChartRange={
                      state.selectedChartRange[state.selectedChartTab]
                    }
                    selectedChartType={
                      state.selectedChartType[state.selectedChartTab]
                    }
                    selectedChartDataType={
                      state.selectedChartDataType[state.selectedChartTab]
                    }
                    handleChangeChartType={handleChangeChartType}
                    handleChangeChartDataType={handleChangeChartDataType}
                    handleLegendChange={handleLegendChange}
                    showScenarios={Boolean(showScenarios)}
                  />
                )}
                <FormatChartPanel
                  open={state.isFormatChartPanelOpen[state.selectedChartTab]}
                  timescale={
                    state.timescale[state.selectedChartTab] as Timescale
                  }
                  handleCloseEditChartPanel={handleCloseFormatChartPanel}
                  formatKey={
                    state.chartFormatKey[state.selectedChartTab] as number
                  }
                  yAxisTitle={state.chartYAxisTitle[state.selectedChartTab]}
                  handleSaveFormatChartPanel={handleSaveFormatChartPanel}
                  chartDateType={state.chartDateType[state.selectedChartTab]}
                  magnitudeTruncation={
                    state.selectedMagnitudeTruncationValue[
                      state.selectedChartTab
                    ]
                  }
                  decimalPlaces={
                    state.selectedDecimalPlacesValue[state.selectedChartTab]
                  }
                  prefix={state.selectedPrefixValue[state.selectedChartTab]}
                  suffix={state.selectedSuffixValue[state.selectedChartTab]}
                />
                <Box pb={4} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default OutputsPanel;

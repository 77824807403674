import React, { FunctionComponent, useEffect } from 'react';
import {
  IconButton,
  Tab,
  Box,
  Card,
  CardContent,
  Typography,
  Stack,
  Tabs,
} from '@mui/material';
import { Undo } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';
import { useSearchParams } from 'react-router-dom';

import { Client } from '../../../types/models';

import PortfolioReportGeneratedFileDownload from '../PortfolioReportGeneratedFileDownload';

import { IPortfolioReportStateData } from '../index';

import useSetState from '../../../hooks/useSetState';

import { PortfolioDataSourceData } from '../types';

import { useData } from '../../../hooks/useData';

import { useProfile } from '../../../hooks/useProfile';

import { parseId } from '../../../utils/parsing';

import ReportProperties from './ReportProperties';
import ReportSharing from './ReportSharing';
import Waterfall from './ReportEdit/Waterfall';
import EditPortfolioSourceType from './ReportEdit/EditPortfolioSourceType';

export interface IPortfolioReportDetailsProps {
  selectedReport?: IPortfolioReportStateData;
  runReport: (report: IPortfolioReportStateData) => void;
  generatedFileID?: number;
  isGeneratedFileDialogOpen: boolean;
  onCloseGeneratedFileDialog: () => void;
  onClose: () => void;
  clientId?: Client['id'];
  refreshReports: () => void;
  refreshDataSource: (id?: PortfolioDataSourceData['id']) => void;
}

interface IPortfolioReportDetailsState {
  selectedTab: string;
  isAnyFieldUpdated: boolean;
}

const initialPortfolioReportDetailsState: IPortfolioReportDetailsState = {
  selectedTab: 'edit',
  isAnyFieldUpdated: false,
};

export const ConfirmationMessageForUnSavedChanges: string =
  'You have unsaved changes, are you sure you want to leave?';
// export const RunBtnConfirmationMessageForUnSavedChanges: string =
//   'You have unsaved changes, are you sure you want to run report?';

const PortfolioReportDetails: FunctionComponent<
  IPortfolioReportDetailsProps
> = (props: IPortfolioReportDetailsProps) => {
  const [state, setState] = useSetState<IPortfolioReportDetailsState>(
    initialPortfolioReportDetailsState
  );

  const [searchParams] = useSearchParams();

  const profile = useProfile();

  const clientId =
    profile && profile.User.ClientID !== null
      ? profile.User.ClientID
      : parseId(searchParams.get('client'));

  const { data, refresh } = useData<{
    reportData?: IPortfolioReportStateData;
    dataSource?: PortfolioDataSourceData;
  }>(
    () => ({
      reportData:
        props.selectedReport?.id !== undefined
          ? `/clients/${clientId}/portfolio_reports/${props.selectedReport?.id}`
          : undefined,
      dataSource:
        props.selectedReport !== undefined
          ? `/portfolio_datasources/${props.selectedReport.DataSourceID}`
          : undefined,
    }),
    [props.selectedReport]
  );

  useEffect(() => {
    refresh();
  }, []);

  const handleNavbarTabChange = (selectedTab: string): void =>
    setState({ selectedTab, isAnyFieldUpdated: false });

  const navigateToReportsHomePage = () => {
    props.onClose();
  }

  const unSavedDataPrompt = (
    message: string,
    callback?: Function,
    ...params: Array<any>
  ) => {
    if (state.isAnyFieldUpdated && !window.confirm(message)) {
      return;
    }
    setState({ isAnyFieldUpdated: false });
    window.onbeforeunload = () => null;

    callback && callback(...params);
  };

  const isThereAnUnsavedComponent = (isAnyFieldUpdated: boolean) =>
    setState({ isAnyFieldUpdated });

  return (
    <>
      <PortfolioReportGeneratedFileDownload
        progressMsg="Preparing download"
        onClose={props.onCloseGeneratedFileDialog}
        generatedFileId={props.generatedFileID}
        isOpen={props.isGeneratedFileDialogOpen}
        reportData={props.selectedReport}
      />
      <Card>
        <CardContent>
          <Stack
            spacing={2}
            direction="row"
            sx={{
              justifyContent: 'space-between',
              borderBottom: 1,
              borderColor: 'divider',
            }}
          >
            <Box sx={{ width: '100%' }}>
              <Grid container alignItems="center">
                <Grid md={0.5}>
                  <IconButton
                    title="Back to Portfolio Dashboard"
                    onClick={() => {
                      unSavedDataPrompt(
                        ConfirmationMessageForUnSavedChanges,
                        props.onClose
                      );
                    }}
                  >
                    <Undo />
                  </IconButton>
                </Grid>
                <Grid md={10.5}>
                  <Typography variant="h5">
                    {props.selectedReport?.Name}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={state.selectedTab}
                onChange={(_, newTab) =>
                  unSavedDataPrompt(
                    ConfirmationMessageForUnSavedChanges,
                    handleNavbarTabChange,
                    newTab
                  )
                }
              >
                <Tab label="Report" value="edit" />
                <Tab label="Properties" value="reportProperties" />
                <Tab label="Share" value="share" />
              </Tabs>
            </Box>
          </Stack>
          <CardContent>
            {state.selectedTab === 'reportProperties' && (
              <ReportProperties
                selectedReport={data.reportData}
                refreshReports={props.refreshReports}
                clientId={props.clientId}
                refreshReport={refresh}
              />
            )}
            {state.selectedTab === 'share' && (
              <ReportSharing
                clientId={props.clientId}
                selectedReport={data.reportData}
              />
            )}
            {state.selectedTab === 'edit' && (
              <>
                {props.selectedReport?.ReportType === 'Waterfall' && (
                  <Waterfall
                    selectedReport={data.reportData}
                    isThereAnUnsavedComponent={isThereAnUnsavedComponent}
                    refresh={refresh}
                    navigateToReportsHomePage={navigateToReportsHomePage}
                  />
                )}
                {props.selectedReport?.ReportType === 'Portfolio' && (
                  <EditPortfolioSourceType
                    showComponentInADialog={false}
                    selectedReportDataSourceData={data.dataSource}
                    clientId={props.clientId}
                    selectedReport={props.selectedReport}
                    refreshReports={props.refreshReports}
                    refreshDataSource={props.refreshDataSource}
                    isThereAnUnsavedComponent={isThereAnUnsavedComponent}
                  />
                )}
              </>
            )}
          </CardContent>
        </CardContent>
      </Card>
    </>
  );
};

export default PortfolioReportDetails;
